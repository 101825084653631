import React, {useState, useEffect} from 'react'
import startupLogo from '../../../../assets/images/blog/p3.jpg'
import {BsArrowRightShort} from 'react-icons/bs'
import AuthService, {apiURL} from '../../../../utils/Common'
import axios from "axios"
import { Link, useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
// import Spinner from 'react-bootstrap/Spinner';
import { config } from '../../../dashboard/overview/Ov'

const DevelopmentStageDetail = (props) => {

    const currentUser = AuthService.getCurrentUser()
    const[errorMessage, setErrorMessage] = useState("")
    const[successMessage, setSuccessMessage] = useState("")
    const [isloading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(0);

    const[startupStage, setStartupStage] = useState([]);
    const navigate = useNavigate()

    // =====================DELETE CATEGORY BY UUID =============================================
    const deleteUrl =`${apiURL}startup_development_stage/delete/`


    const handleDelete = (index) => {
        return axios.post(deleteUrl, {'uuid':index} ,config)
            .then(response => {
                if (response.status === 200) {
                    alert("Dev Stage successfully Deleted");
                    //props.history.push("/categories");
                    // console.log(response.data);
                    setStartupStage(newStage)
                    // navigate("/dashboard/admin/setup/dev_stages/")
                    window.location.reload()
                    setSuccessMessage("Dev stage Deleted successfully")
                  }else Promise.reject();
                
            return 
        }).catch(error => {
            setErrorMessage(error.response.message)
        })
    }
 
// ===========================================================================
//  Get all categories
    


    useEffect(() => {
        const getUrl = apiURL + "startup_development_stage/list/"
        setLoading(true)
        const getDevStages = () => {
            setIsLoading(true)
            return axios.get(getUrl, config)
             .then(response => {
                if (response.status === 200) {
                     //console.log(response.data);
                    setStartupStage(response.data.data)  
                    setIsLoading(false)              
                }else Promise.reject()                  
              return 
            }).catch((error) => {
                setErrorMessage(error.response.data.message)
                setIsLoading(false)
            })
            
          };
          getDevStages()
      },[loading])
    //   if (loading) return <Spinner />;

    //   console.log(startupCategory);
    const listStages = startupStage.map((stage, i) =>
        <tr key={stage.uuid}>
            <th scope="row">{i}</th>
            <td>{stage.name}</td>
            <td><Link to={`/dashboard/admin/setup/dev_stages/edit/${stage.uuid}`}
                // state={{from: category.name}}
                type="button" className="btn btn-info">Edit</Link></td>
            <td><button onClick={()=>handleDelete(stage.uuid)} 
                type="button" className="btn btn-danger">Delete</button></td>
        </tr>

    )

// ================================================================================
    // Create New Categories

    // console.log(getCategory());
    const createUrl = `${apiURL}startup_development_stage/create/`

    const [newStage, setNewStage] = useState({
        name: ""
      })
    const handleChange = e => {
        const {name, value} = e.target
        setNewStage({
          ...newStage, 
          [name]:value
        })
      }

  
    const createStage = (e) => {
        e.preventDefault();
        console.log(newStage);
        setLoading(true)
        axios.post(createUrl, newStage, config)
            .then(response => {
                if (response.status === 200) {
                    setNewStage(response.data)
                    setSuccessMessage('Dev Phase Created Successfully')
                    setLoading(false)        
                //window.location.reload();  
                }else Promise.reject()   
                
                setNewStage({name: ''})          
                
            }).catch(error => {
                setErrorMessage(error.response.data.message)
            })
            


    }
  return (
  <section>
    <div className="overview-title">
        <h1>Startup Development Phase(Stage - MVP, Prototype etc)</h1>
    </div>
    <div className="row">
        <div className="col-md-6">
                <div className="startup-d-container">
                <div className="startup-d-header">
                    <div className="startup-header-info">
                        <p className="startup-category">Development phase</p>
                        <h3 className="startup-name">Available Data</h3>
                    </div>

                </div>
                <div className="startup-d-desc">
                
                <table className="table table-striped">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Development Phase</th>
                        <th scope="col">Edit</th>
                        <th scope="col">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isloading ? 
                            <div className="loading">Loading...</div>
                        :
                            listStages
                        }
                        
                    </tbody>
                    </table>

                </div>
                <div className="startup-d-contact">
                    <a href="/contact">Contact Startup <BsArrowRightShort/></a>
                </div>
            </div>
        </div>
        <div className="col-md-6">
                <div className="startup-d-container">
                <div className="startup-d-header">
                    <div className="startup-header-info">
                        <p className="startup-category">Startup Phase</p>
                        <h3 className="startup-name">Create Phase(stage)</h3>
                    </div>

                </div>
                <div className="startup-d-desc">
                <span className="text-success">{successMessage}</span>
                <div className="contact-form">
                            
                            <span className="text-danger">{errorMessage}</span>
                            <form action="#" method="post" onSubmit={createStage} className="row">
                                <div className="col-md-12">
                                    <label htmlFor="category">Startup phase name</label>
                                    <input type="text" name="name" value={newStage.name} 
                                     onChange={handleChange}
                                    placeholder="Startup phase Name"/>
                                </div>
                                
                                <div className="col-md-12 text-right">
                                   
                                    <input type="submit" name="submit" value="Create New Phase"/>
                                </div>
                            </form>
                        </div>
          

                </div>
                <div className="startup-d-contact">
                    {/* <a href="/contact">Contact Startup <BsArrowRightShort/></a> */}
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default DevelopmentStageDetail