import React, {useState, useEffect} from 'react'
import './investor.css'
import AuthService, {apiURL, imgUrl} from "../../utils/Common"
import axios from 'axios'

// import heroThumb2 from '../../assets/vid/st2.gif'

const Investor = ({userInfo, setUserInfo, investor, setInvestor}) => {
    const currentUser = AuthService.getCurrentUser()
    const[investorProfile, setInvestorProfile] = useState({})
    const[loading, setLoading] = useState(false)
    // const investor_uuid = 
    const [intId, setIntId] = useState(userInfo.investor_uuid)
    // console.log(investor_uuid);
    const[successMessage, setSuccessMessage] = useState('')
    // console.log(investor_uuid);
    // console.log(userInfo);
//   using access token as authorisation for bearer to pass authenticated request for updates
const token = currentUser.access_token;  
    const config = {
    headers: { 
        "Authorization": `Token ${token}`,
        "Content-Type": "multipart/form-data"
    }
};
// =====================GET INVESTOR ID PROFILE===
       // Get investor
       const intUrl = `${apiURL}vc/get/`   
       const getInvestor = async () => {
            return axios.post(intUrl, {'investor_uuid':intId}, config)
                .then(response => {
                    if (response.status === 200) {
                        setInvestor(response.data)
                        // console.log(investor);
                    }                       
                    return
                }).catch(error => {
                    console.log('error here');
                })
        }   
   
       useEffect(() => {                     
           getInvestor()
         }, [loading])

//====================GET ALL CATEGORIES=================
const [catData, setCatData] = useState([])
useEffect(() => {
    const getUrl = apiURL + "startup_categories/list/"
    const getCategory = async() => {
        return axios.get(getUrl, config)
         .then(response => {
            setCatData(response.data.data)
          return 
        })
      };
      getCategory()
  },[loading])

      //   console.log(startupCategory);
const listCategory = catData.map((category, i) =>
    <option key={category.uuid} value={category.uuid}>{category.name}</option>   

)

//====================GET ALL DEV Stages(Phases)=================
const [devStage, setDevStage] = useState([])
    useEffect(() => {
        const getUrl = apiURL + "startup_development_stage/list/"
        const getDevStages = async() => {
            return axios.get(getUrl, config)
             .then(response => {
                if (response.status === 200) {
                    setDevStage(response.data.data)
                }else Promise.reject()                                       
              return 
            })
          };
          getDevStages()
      },[loading])
      const listDevStage = devStage.map((stage, i) =>
        <option key={stage.uuid} value={stage.uuid}>{stage.name}</option> 
      )

  //====================GET ALL Raising Funds (Phases)=================
  const [raisePhase, setRaisePhase] = useState([])
  useEffect(() => {
      const getUrl = apiURL + "startup_raising_phase/list/"
      const getRaisePhases = async() => {
          return axios.get(getUrl, config)
           .then(response => {
              if (response.status === 200) {
                  setRaisePhase(response.data.data)
              }else Promise.reject()                                       
            return 
          })
        };
        getRaisePhases()
    },[loading])

//===================================== 
//   console.log(startupCategory);
const listRaisePhase = raisePhase.map((raise, i) =>
    <option key={raise.uuid} value={raise.uuid}>{raise.name}</option>   
)

// =========handle investor update========================
    const url = `${apiURL}vc/update/`
    const handleChange = e => {
        const {name, value} = e.target
        setInvestor({
          ...investor, investor_uuid:intId  , 
          [name]:value
        })
      }
    const handleInvestorUpdate =  (e) => {
        e.preventDefault()
        console.log(investor);
        setLoading(true)
        axios.post(url,investor, config )
            .then(response => {
                if (response.status === 200) {
                    
                    setInvestor(response.data)
                    setLoading(false)
                    setSuccessMessage('Your Investor Profile has been set Successfully')
                }
                // console.log(response.data);
                
            }).catch(error => {
                console.log(error.response.data);
                setLoading(false)
            })
    }




  return (
    
    
        <section className="contact-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-1 col-xs-12 offset-xs-12">
                        <div className="contact-form">
                            <h4>Complete Your Investor Profile</h4>
                            <span className="text-info">{successMessage}</span>
                            <form action="#" method="post" onSubmit={handleInvestorUpdate} className="row">
                                <div className="col-md-12">
                                    <label htmlFor="company">Company Name</label>
                                    <input type="text" name="company" value={investor.company}  
                                        onChange={handleChange} placeholder="Company Name"/>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="title">Title</label>
                                    <input type="text" name="title" value={investor.title}  
                                        onChange={handleChange} placeholder="General partner..."/>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="check_size">Check Size($)</label>
                                    <input type="number" name="check_size" 
                                        onChange={handleChange}
                                        value={investor.check_size} 
                                        placeholder="eg $10k, $20k etc"/>
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="geographical">Geographical Area Of Interest</label>
                                    <input type="text" name="geographical_area_of_interest" 
                                       value={investor.geographical_area_of_interest} 
                                       onChange={handleChange}
                                       placeholder="Geographical Area of interest"/>
                                </div>
                                <div className="col-md-12 mb-2">
                                    <label htmlFor="categories">Sector of Interest</label>
                                    <select name="categories" value={investor.categories} 
                                        onChange={handleChange}
                                        className='form-control'>
                                        <option value=""></option>
                                        {listCategory}
                                    </select>
                                </div>
                                {/* <div className="col-md-12 mb-2">
                                    <label htmlFor="stages">Investment Stage</label>
                                    <select name="stages" value={investor.stages} 
                                        onChange={handleChange}
                                        className='form-control'>
                                        <option value=""></option>
                                        {listDevStage}
                                    </select>
                                </div> */}
                                <div className="col-md-12 mb-2">
                                    <label htmlFor="phases">Investment Phase</label>
                                    <select name="phases" value={investor.phases} 
                                        onChange={handleChange}
                                        className='form-control'>
                                        <option value=""></option>
                                        {listRaisePhase}
                                    </select>
                                </div>
                                <div className="col-md-12 mb-2">
                                    <label htmlFor="Website">Website</label>
                                    <input type="url" name="website" 
                                        value={investor.website} 
                                        onChange={handleChange}
                                        className="form-control" placeholder="https://example.com"/>
                                </div>
                                <div className="col-md-12">
                                <label htmlFor="more_info">More Info</label>
                                    <textarea name="more_info" value={investorProfile.more_info} 
                                        onChange={handleChange}
                                        placeholder="How can we help?">
                                            {investor.more_info}
                                    </textarea>
                                </div>
                                <div className="col-md-6">
                                    {/* <div className="condition-check">
                                        <input id="terms-conditions" type="checkbox"/>
                                        <label for="terms-conditions">
                                            I agree to the <a href="#">Terms & Conditions</a>      
                                        </label>
                                    </div> */}
                                </div>
                                <div className="col-md-6 text-right">
                                    <button className='btn btn-primary'>
                                        {loading ? 
                                            <div className='btn btn-danger disabled'>Processing...</div> 
                                            : 
                                            <>Update Investor Profile</>}
                                    </button>
                                    {/* <input type="submit" name="submit" value="Update Investor Profile"/> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

  )
}

export default Investor