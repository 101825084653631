import React from 'react'
import './startups.css'
// import EditStartupSettingDetail from './EditStartupSettingDetail'
import DashboardSidebar from '../../../../components/dashboard/DashboardSidebar'
import DashbordNavbar from '../../../../components/dashboard/DashbordNavbar'
import EditStartUp from './create_startup/EditStartUp'


const EditMyStartup = () => {
  return (
    <div className='admin-dashboard'>
        <DashbordNavbar/>
        <DashboardSidebar/>
        <div className="dashboard-main">
        <EditStartUp/>
        {/* <CreateStartUp/> */}

      </div>
    </div>
  )
}

export default EditMyStartup