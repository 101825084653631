import React from 'react'
import logoImg from '../../assets/images/logo.png'
import {FaRegEnvelope, FaFacebookF,FaTwitter,FaInstagramSquare,FaLinkedinIn,FaApple} from 'react-icons/fa'
import {AiOutlinePhone} from 'react-icons/ai'
import {IoLogoGooglePlaystore} from 'react-icons/io5'
import { Link, useNavigate } from "react-router-dom";
import {HiOutlineLocationMarker} from 'react-icons/hi'

const Footer = () => {
  return (
    <section className="appie-footer-area">
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-md-6">
                    <div className="footer-about-widget">
                        <div className="logo">
                            <a href="#"><img src={logoImg} alt=""/></a>
                        </div>
                        <p>Empowering your Business Need, Serving Information, Serving you.</p>
                        <Link to="/about">Read More<i className="fal fa-arrow-right"></i></Link>
                        <div className="social mt-30">
                            <ul>
                                <li><a href="#"><FaFacebookF/></a></li>
                                <li><a href="https://twitter.com/startups4africa"><FaTwitter/></a></li>
                                <li><a href="#"><FaInstagramSquare/></a></li>
                                <li><a href="#"><FaLinkedinIn/></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6">
                    <div className="footer-navigation">
                        <h4 className="title">Company</h4>
                        <ul>
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/startups">Portfolio</Link></li>
                            <li><Link to="/contact">Contact Us</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="footer-navigation">
                        <h4 className="title">Support</h4>
                        <ul>
                            <li><a href="#">Faqs</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">Careers</a></li>
                        </ul>
                    </div>                    
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="footer-widget-info">
                        <h4 className="title">Get In Touch</h4>
                        <ul>
                            <li><a><FaRegEnvelope/> support@Startups4Africa.com</a></li>
                            <li><a ><AiOutlinePhone/> +(237) 674 993 604</a></li>
                            <li><a><HiOutlineLocationMarker/> Sonac Strt, Bamenda, Cameroon, Africa</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                        <div className="apps-download-btn">
                        {/* <ul>
                            <li><a href="#"><FaApple/> Download for iOS</a></li>
                            <li><a className="item-2" href="#"><IoLogoGooglePlaystore/> Download for Android</a></li>
                        </ul> */}
                        </div>
                        <div className="copyright-text">
                            <p>Copyright © 2022 Startups4Africa. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Footer