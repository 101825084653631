import React from 'react'
import Features from '../components/features/Features'
import Footer from '../components/footer/Footer'
import Hero from '../components/hero/Hero'
import Navbar from '../components/navbar/Navbar'
import Projects from '../components/project/Projects'
import Services from '../components/services/Services'
import Anime from '../components/anime/Anime'
import Team from '../components/team/Team'
import Traffic from '../components/traffic/Traffic'
import CanvasMenu from '../components/CanvasMenu'

const Home = () => {
  
  return (
    <div>
      <CanvasMenu/>
      <Navbar type="main"/>
      <Hero/>
      
      <Anime/>
      <Traffic/>
      <Services/>      
      <Features/>
      

      {/* <Team/> */}
      <Projects/>
      <Footer/>
    </div>
  )
}

export default Home