import React from 'react'
import './vc.css'
import DashboardSidebar from '../../../components/dashboard/DashboardSidebar'
import DashbordNavbar from '../../../components/dashboard/DashbordNavbar'
import InvestorEdit from '../../../components/investor/InvestorEdit'
// import Investor from '../../../components/investor/InvestorEdit'

const EditInvestor = () => {
  return (
      <div className='admin-dashboard'>
        <DashbordNavbar/>
        <DashboardSidebar/>
        <div className="dashboard-main">
          <InvestorEdit />

        </div>
      </div>
  )
}

export default EditInvestor