import React from 'react'
import logoImg from '../assets/images/logo.png'
import {AiFillCloseSquare} from 'react-icons/ai'

const CanvasMenu = () => {
  return (
    <div>
        <div className="off_canvars_overlay"> </div>
                <div className="offcanvas_menu">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="offcanvas_menu_wrapper">
                                    <div className="canvas_close">
                                        <AiFillCloseSquare/> 
                                    </div>
                                    <div className="offcanvas-brand text-center mb-40">
                                        <img src={logoImg} alt=""/>
                                    </div>
                                    <div id="menu" className="text-left ">
                                        <ul className="offcanvas_main_menu">
                                            <li className="menu-item-has-children active">
                                                <a href="#">Home</a>
                                                <ul className="sub-menu">
                                                    <li><a href="index.html">Home 1</a></li>
                                                </ul>
                                            </li>
                                            <li className="menu-item-has-children active">
                                                <a href="#service">Service</a>
                                            </li>
                                            <li className="menu-item-has-children active">
                                                <a href="#features">Feature</a>
                                            </li>
                                            <li className="menu-item-has-children active">
                                                <a href="#testimonial">Testimonial</a>
                                            </li>
                                            <li className="menu-item-has-children active">
                                                <a href="#">News</a>
                                                <ul className="sub-menu">
                                                    <li><a href="index.html">news page</a></li>
                                                    <li><a href="index-2.html">Single News</a></li>
                                                </ul>
                                            </li>
                                            <li className="menu-item-has-children active">
                                                <a href="contact.html">Contact</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="offcanvas-social">
                                        <ul className="text-center">
                                            <li><a href="$"><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a href="$"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="$"><i className="fab fa-instagram"></i></a></li>
                                            <li><a href="$"><i className="fab fa-dribbble"></i></a></li>
                                        </ul>
                                    </div>
                                    <div className="footer-widget-info">
                                        <ul>
                                            <li><a href="#"><i className="fal fa-envelope"></i> support@appie.com</a></li>
                                            <li><a href="#"><i className="fal fa-phone"></i> +(642) 342 762 44</a></li>
                                            <li><a href="#"><i className="fal fa-map-marker-alt"></i> 442 Belle Terre St Floor 7, San Francisco, AV 4206</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
    </div>
  )
}

export default CanvasMenu