import React, { useState, useEffect } from 'react'
import './SettingsDetails.css'
import startupLogo from '../../../assets/images/blog/p3.jpg'
import startupNoImage from '../../../assets/images/blog/programmer.png'
import {BsArrowRightShort} from 'react-icons/bs'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import AuthService, {apiURL, imgUrl} from '../../../utils/Common'
import { useParams } from 'react-router-dom'
import { config} from '../overview/Ov'
import axios from 'axios';
import ImageUploading from 'react-images-uploading';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SettingsDetail = ({userInfo, setUserInfo}) => {
    // console.log(userInfo.photo);

    const currentUser = AuthService.getCurrentUser()
    const profileImg = imgUrl + userInfo.photo
    const[errorMessage, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(false);
    const [userlog, setUserLog] = useState(false)

    const [phoneNumber, setPhoneNumber] = useState()
    // const [value, setValue] = useState()
    const [startDate, setStartDate] = useState(new Date());

    const [userImage, setUserImages] = React.useState(null);
    // console.log(userImage);

    // get user ID and access token
    const user_uuid = currentUser.user_uuid;


// ========================GET USER DATA=============================================
    // const[userData, setUserData] = useState([]);
    const getUserUrl = `${apiURL}users/get/${user_uuid}/`
    useEffect(() => {
        setUserLog(true)
        const getUserData = async (user_uuid) => {
            return axios.get(getUserUrl, config)
                .then((response) => {
                    if (response.status === 200) {
                        // const { first_name,last_name, role } = response.data;
                    setUserInfo(response.data)
                    setUserImages(imgUrl+response.data.photo)
                    setUserLog(false)
                    }else Promise.reject()
                    
                }).catch((error) => {
                    setErrorMessage(error.response.message)
                })
        }; getUserData()
    }, [user_uuid]);
    

      
    

    function formatDate(newDate) {
        //var newDate = new Date();
        var month = newDate.getUTCMonth() + 1; //months from 1-12
        var day = newDate.getUTCDate();
        var year = newDate.getUTCFullYear();

        var newdate = year + "-" + month + "-" + day;
        return newdate;
        
    }
    

    // const params = useParams();
    // console.log(userInfo);
    const handleFileSelect = (event) => {
        setUserImages(event.target.files[0])
      }
    // ===============================================IMAGE UPLOAD================================
    const onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
        let picture = event.target.files[0];
        let src = URL.createObjectURL(picture)
        // console.log(src);
        setUserImages({
            picture:picture,
            src:src
        });
        }
    };

    const  uploadImage = () => {
        const data = new FormData() 
        data.append('photo', userImage)
        // console.warn(userImage);
        // console.log(data);
        axios.post(url, data, config)
        .then(response => { // then print response status
            setUserInfo(response.data)
            // console.warn(res);
        })
    }

    const handleChange = e => {
        const {name, value} = e.target
        setUserInfo({
          ...userInfo, date_of_birth: formatDate(startDate), phone:phoneNumber, photo:userImage,
          [name]:value
        })
      }

// =====================================UPDATE USER DATA================================
    const url = `${apiURL}users/update/${user_uuid}/`

    const handleProfileUpdate = (e) => {
        e.preventDefault()

        const data = new FormData() 
        data.append(userImage, userImage)
        // console.warn(userImage);
        // console.log(userImage);
        setLoading(true)
        // console.log(userInfo);
        uploadImage()
        axios.post(url, userInfo, config)
            .then(response => {
                if (response.status === 200) {
                    alert("Profile successfully updated");
                    // window.location.reload();
                    setUserInfo(response.data)
                    setUserInfo(imgUrl+response.data.photo)
                    setLoading(false)
                  }else Promise.reject()
                
                // console.log(response.data);
            }).catch(error => {
                setErrorMessage(error.response.data.message)
            })
    }

  return (
  <section>
    <div className="overview-title">
        <h1>User Profile</h1>
    </div>
    <div className="row">
        <div className="col-md-6">
            <div className="startup-d-container">
                <div className="startup-d-header">

                            {userlog ? 
                                <div className="loading">Loading Data ....</div>
                            :
                                <>
                                    <div className="startup-header-logo">
                                        {/* <img src={userInfo.photo} alt="User profile image" /> */}
                                        {profileImg === false ?
                                            <img 
                                            style={{width:"150px", height:"auto", borderRadius:"50px"}} 
                                            src={startupNoImage} alt="No User profile" />
                                            : 

                                            <img style={{width:"150px", height:"auto", borderRadius:"50px"}} 
                                            src={profileImg} alt="user profile" />
                                        }
                                    </div>
                                    <div className="startup-header-info">
                                        <p className="startup-category">{userInfo.name}</p>
                                        <p className="startup-founder-date">Email: {userInfo.email}</p>
                                        <p className="startup-founder-date">Data of Birth | {userInfo.date_of_birth}</p>
                                        <p className="startup-founder-date">User role | {userInfo.role}</p>
                                        <p className="startup-founder-date">User Phone Number | {userInfo.phone}</p>
                                    </div>
                                </>
                            }
                    

                </div>
                <div className="startup-d-desc"> <strong>About Me :</strong><br />
                {userInfo.about_me}

                </div>
                <div className="startup-d-contact">
                    <a>My Startups <BsArrowRightShort/></a>
                </div>
            </div>
        </div>
        <div className="col-md-6 row">
            <div className="startup-d-container">
                <div className="startup-d-header">
                    
                    <div className="startup-header-info row">
                        <p className="startup-category">Edit My Profile</p>
                    </div>

                </div>
                <div className="contact-form">
                            <span className="text-danger">{errorMessage}</span>
                            <form action="#" method="post" onSubmit={handleProfileUpdate} enctype="multipart/form-data" className="row">
                                <div className="col-md-6">
                                    <label htmlFor="first_name">First Name</label>
                                    <input type="text" name="first_name" 
                                    value={userInfo.first_name} 
                                    onChange={handleChange}
                                    placeholder="First Name"/>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="last_name">Last Name</label>
                                    <input type="text" name="last_name" value={userInfo.last_name}  
                                        onChange={handleChange} placeholder="Last Name"/>
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="phoneNumber">Phone Number</label>
                                    <PhoneInput className='phonetype' name="phone"
                                        international
                                        defaultCountry="CM"
                                        placeholder="Enter phone number"
                                        value={phoneNumber}
                                        onChange={setPhoneNumber}
                                        // onChange={handleChange}
                                        />
                                </div> 
                                <div className="col-md-12">
                                    <label htmlFor="date">Date of Birth</label>
                                    <DatePicker className="dob" 
                                        name="date_of_birth"
                                        dateFormat="yyyy-MM-dd"
                                        // value={userInfo.date_of_birth}
                                        selected={startDate} 
                                        onChange={(date) => setStartDate(date)}
                                          />
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="country">Country</label>
                                    <input type="text" name="country" value={userInfo.country}  
                                        onChange={handleChange} placeholder="Country"/>
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="userImage">Upload Image</label>
                                    <input type="file" name="userImage"   
                                        onChange={handleFileSelect} placeholder="image"/>
                                </div>
                                {/* {userImage.src && <img style={{width:"250px", height:"auto"}} src={userImage.src} alt="user profile" />} */}
                                {/* <div className="col-md-12">
                                    <label htmlFor="image">Upload Image</label>
                                    <input type="file" name="photo"   
                                        onChange={handleChange} placeholder="Country"/>
                                </div> <br /> */}
                                <div className="col-md-12">
                                <label htmlFor="about_me">About Me</label>
                                    <textarea name="about_me" value={currentUser.about_me}  
                                        onChange={handleChange} placeholder="My Bio?">
                                     {userInfo.about_me}
                                    </textarea>
                                </div>
                                <div className="col-md-6 text-right">
                                   
                                    <input type="submit" name="submit" value="Update Profile"/>
                                </div>
                            </form>
                        </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default SettingsDetail