import React, {useState, useEffect} from 'react'
import './vc.css'
import AuthService, {apiURL, imgUrl} from "../../../utils/Common"
import axios from 'axios'
import Investor from '../../../components/investor/InvestorEdit'
import startupNoImage from '../../../assets/images/blog/programmer.png'
import { Link } from 'react-router-dom'
import { config } from '../overview/Ov'
import { VcCat } from './VcCat'
import { VcStage } from './VcStage'

const VcProfileDetails = ({investor, userInfo}) => {
 

    const currentUser = AuthService.getCurrentUser()
    const [category, setCategory] = useState([])
    const investorImg = imgUrl + userInfo?.photo
    const categoryData = investor?.categories
    const stage = investor?.stages

    console.log(stage);
    if (investor?.stages === undefined) {
        console.log('no data retrieved');
    }
    if (investor?.categories === undefined) {
        console.log('no data found');
    }



  return (
    <div>
        <section className="section about-section gray-bg" id="about">
            <div className="container">
                <div className="row align-items-center flex-row-reverse">
                    <div className="col-lg-6">
                        <div className="about-text go-to">
                            <h3 className="dark-color">About Me</h3>
                            <h6 className="theme-color lead">{investor.title}</h6>
                            <p>{investor.more_info}</p>
                            <div className="row about-list">
                                <div className="col-md-6">
                                    <div className="media">
                                        <label>Company</label>
                                        <p>{investor.company}</p>
                                    </div>
                                    <div className="media">
                                        <label>Preferred Sector</label>
                                        
                                        <>
                                            {investor?.categories === undefined ?
                                                <>Loading . . .</>
                                                :
                                                investor?.categories.map((category) => (<VcCat key={category.category_uuid} category={category} />))                                         
                                            }
                                        </>
                                       
                                        
                                    </div>
                                    <div className="media">
                                        <label>Investment Stage</label>
                                        
                                        <>
                                            {investor?.stages === undefined ?
                                            <>Loading . . .</>
                                            :
                                            investor?.stages.map((stage) => (<VcStage key={stage.development_stage_uuid} stage={stage} />))                                         
                                            }
                                        </>

                                     
                                    </div>
                                    <div className="media">
                                        <label>Check Size</label>
                                        <p>${investor.check_size}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="media">
                                        <label>E-mail</label>
                                        <p>{userInfo.email}</p>
                                    </div>
                                    <div className="media">
                                        <label>website</label>
                                        <a href={investor.website} target="_blank" className=''>
                                            {investor.website}</a>
                                    </div>
                                    <div className="media">
                                        <label>Area of Interest</label>
                                        <p>{investor.geographical_area_of_interest}</p>
                                    </div>
                                    {/* <div className="media">
                                        <label>Freelance</label>
                                        <p>Available</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-avatar self-align-item">
                            {investorImg === false ?
                                <img style={{width:"250px", height:"auto"}} 
                            src={startupNoImage} alt="No User profile" />
                            : 

                            <img style={{width:"250px", height:"auto"}} 
                            src={investorImg} alt="user profile" />
                            }
                            {/* <img src={investorImg} title="" alt="" c height={300} width="300" /> */}
                            {/* <img src="https://bootdey.com/img/Content/avatar/avatar7.png" title="" alt="" /> */}
                        </div>
                    </div>
                </div>
                <div className="counter">
                    <div className="row">
                        <div className="col-6 col-lg-4">
                            <div className="count-data text-center">
                                <h6 className="count h2" data-to="500" data-speed="500">500</h6>
                                <p className="m-0px font-w-600">Happy Clients</p>
                            </div>
                        </div>
                        <div className="col-6 col-lg-4">
                            <div className="count-data text-center">
                                <h6 className="count h2" data-to="150" data-speed="150">150</h6>
                                <p className="m-0px font-w-600">Project Completed</p>
                            </div>
                        </div>
                        <div className="col-6 col-lg-4">
                            <div className="count-data text-center">
                                <h6 className="count h2" data-to="190" data-speed="190"></h6>
                                <Link to={`/dashboard/vc/investor/edit/profile/${investor.investor_uuid}`} 
                                className="m-0px font-w-600 btn btn-info">Update My Investor Profile</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default VcProfileDetails