import React from 'react'
import shape1Img from '../../assets/images/shape/shape-1.png'
import shape2Img from '../../assets/images/shape/shape-3.png'
import shape3Img from '../../assets/images/shape/shape-4.png'
// import heroThumb2 from '../../assets/images/hero-thumb-2.png'
import heroThumb1 from '../../assets/vid/st.gif'
import { Link, useNavigate } from "react-router-dom";

import {IoLogoGooglePlaystore} from 'react-icons/io5'
import {FaApple} from 'react-icons/fa'


const Hero = () => {
  return (
    <div>
        <section className="appie-hero-area appie-header-3-area appie-sticky">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-xs-12">
                        <div className="appie-hero-content">
                            <span>Welcome To The Startups <strong>MARKETPLACE</strong></span>
                            <h1 className="appie-title">We connect your Amazing Startups to Potential Markets.</h1>
                            <p><strong>Meet Realtime Investors in one place</strong></p>
                            <ul>
                                <li><Link to="/about">About Us</Link></li>
                                <li><Link class="item-2" to="/contact">Contact Us</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-xs-12">
                        <div className="appie-hero-content">
                            {/* <div className="thumb wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                <img src={heroThumb1} alt=""/>
                            </div> */}
                            <div className="thumb-2 wow animated fadeInRight rushCustom" data-wow-duration="2000ms" data-wow-delay="600ms">
                                <img src={heroThumb1} alt=""/>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hero-shape-1">
                <img src={shape1Img} alt=""/>
            </div>
            <div class="hero-shape-2">
            <img src={shape2Img} alt=""/>
            </div>
            <div class="hero-shape-3">
            <img src={shape3Img} alt=""/>
            </div>
        </section>

    </div>
  )
}

export default Hero