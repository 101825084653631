import React, {useState, useEffect} from 'react'
// import startupLogo from '../../../../assets/images/blog/p3.jpg'
import {BsArrowRightShort} from 'react-icons/bs'
import AuthService, {apiURL} from '../../../../utils/Common'
import axios from "axios"
import { Link } from 'react-router-dom'
import './users.css'
// import { useEffect } from 'react'
// import BootstrapTable from 'react-bootstrap-table-next';



const UserDetail = () => {

    const currentUser = AuthService.getCurrentUser()
    const [userData, setUserData] = useState([])
    const[successMessage, setSuccessMessage] = useState("")
    const[errorMessage, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(false);
    // const [loading, setLoading] = useState(false);
    const [isloading, setIsLoading] = useState(false);


      // get user ID and access token
      const token = currentUser.access_token;

      // Authorisation Token
      const config = {
        headers: { 
            "Authorization": `Token ${token}` 
        }
      };
    
// ===========GET USERS==================
      const url = apiURL + "users/list/"
      useEffect(() =>{
      
        const getUsers = () => {
            setIsLoading(true)
            return axios.get(url, config)
             .then(response => {
                setUserData(response.data)
                // console.log(response.data);
                setIsLoading(false)
              return 
            })
          };
          getUsers()

      },[])
    
    // ==============DISPLAY USERS DETAILS=========================
      const listUsers = userData.map((user, i) =>
                <tr key={user.user_uuid}>
                    <th scope="row">{i}</th>
                    <td>{user.first_name}</td>
                    <td>{user.last_name}</td>
                    <td>{user.role}</td>
                    <td>{user.email}</td>
                    <td>{user.phone}</td>
                    <td>{user.date_of_birth}</td>
                    <td>{user.is_active === true ?
                        <button onClick={()=>disableUser(user.user_uuid)} 
                        type="button" className="btn btn-danger">{loading ? <>Loading..</> : <>Disable User</>}</button>
                        :
                        <button onClick={()=>enableUser(user.user_uuid)} 
                        type="button" className="btn btn-primary">{loading ? <>Loading..</> : <>Enable User</>}</button>
                        }</td>
                    <td><Link to={`/dashboard/setup/users/edit/${user.user_uuid}`} 
                        type="button" className="btn btn-info">Edit</Link></td>
                </tr>

                )
// -------=============DISABLE AND ENABLE USER ==============================
// ==========DISBALE =============================
    const dUrl =`${apiURL}users/disable/`
    const disableUser = async(index) => {
        setLoading(true)
        return axios.get(dUrl+`${index}`, config)
            .then(response => {
                if (response.status === 200) {
                    setLoading(false)
                    alert("User successfully Disabled");
                    // console.log(response.data);
                    setSuccessMessage(response.data)
                    window.location.reload();
                  }else Promise.reject();
                
            return 
        }).catch(error => {
            setErrorMessage(error.response.message)
        })
    }
    // =========================ENABLE===============================
    const eUrl =`${apiURL}users/enable/`
    const enableUser = async (index) => {
        setLoading(true)
        return axios.get(eUrl+`${index}`, config)
            .then(response => {
                if (response.status === 200) {
                    alert("User successfully Enabled");
                    // console.log(response.data);
                    setSuccessMessage(response.data)
                    setLoading(false)
                    window.location.reload();
                  }else Promise.reject();
                
            return 
        }).catch(error => {
            setErrorMessage(error.response.message)
        })
    }
 



  return (
  <section>
    <div className="overview-title">
        <h1>All Users Listed</h1>
    </div>
    <div className="row">
        <div className="col-md-12">
                <div className="startup-d-container">
                <div className="startup-d-header">
                    <div className="startup-header-info">
                        <p className="startup-category">All Users</p>
                        <h3 className="startup-name">Users</h3>
                    </div>

                </div>
                <div className="startup-d-desc">
                <table className="table table-striped">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">User Role</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone Number</th>
                        <th scope="col">Date of Birth</th>
                        <th scope="col">Enable/Disable</th>
                        <th scope="col">Edit User</th>
                        </tr>
                    </thead>
                    <tbody>

                        {isloading ?
                            <div className="loading">Loading ...</div>
                        :
                            listUsers
                        }
                        
                    </tbody>
                    </table>

                </div>
                <div className="startup-d-contact">
                    <a href="/contact">Contact Startup <BsArrowRightShort/></a>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default UserDetail