import React from 'react'
import './services.css'


import Img1 from '../../assets/images/icon/1.png'
import Img3 from '../../assets/images/icon/3.png'
import Img4 from '../../assets/images/icon/4.png'

const Services = () => {
  return (
    
    
    <section className="appie-service-area pt-90 pb-100" id="service">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-lg-6">
                <div className="appie-section-title text-center">
                    <h3 className="appie-title">What are we About?.</h3>
                    <p>Providing a first check safe-space and interconnections between 
                        creative startups Ideas, and solutions with 
                        Investors.
                        We basically want to encourage every African to leverage their ideas into a
                         potential business for social change be it technical or nontechnical founders.
                    </p>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-3 col-md-6">
                <div className="appie-single-service text-center mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                    <div className="icon">
                        <img src={Img1} alt=""/>
                        <span>1</span>
                    </div>
                    <h4 className="appie-title">Researching</h4>
                    <p>We research existing market opportunities for you.</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-6">
                <div className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                    <div className="icon">
                        <img src="assets/images/icon/2.png" alt=""/>
                        <span>2</span>
                    </div>
                    <h4 className="appie-title">Designing</h4>
                    <p> Designing Complex Things Easily. Visual treats to engage the audience.</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-6">
                <div className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                    <div className="icon">
                        <img src={Img3} alt=""/>
                        <span>3</span>
                    </div>
                    <h4 className="appie-title">Building</h4>
                    <p>We turn complex designs and problems into Simple Market-Ready Solutions.</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-6">
                <div className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="800ms">
                    <div className="icon">
                        <img src={Img4} alt=""/>
                        <span>4</span>
                    </div>
                    <h4 className="appie-title">Suporting</h4>
                    <p>We build your business and Startup with you.</p>
                </div>
            </div>
        </div>
    </div>
</section>

  )
}

export default Services