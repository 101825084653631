import React, {useState, useEffect} from 'react'
import startupLogo from '../../../../assets/images/blog/p3.jpg'
import {BsArrowRightShort} from 'react-icons/bs'
import AuthService, {apiURL} from '../../../../utils/Common'
import axios from "axios"
import {useNavigate, useLocation, useParams} from "react-router-dom";
import { config } from '../../../dashboard/overview/Ov'

const CategoryEditDetails = ({index}, props) => {
    const {id} = useParams(index);
    const categoryId = {id}.id

    

    const navigate = useNavigate()

    const currentUser = AuthService.getCurrentUser()
    const[errorMessage, setErrorMessage] = useState("")
    const[successMessage, setSuccessMessage] = useState("")
    const [loading, setLoading] = useState(false);

    const[categoryInfo, setCategoryInfo] = useState({
        name: '',
        slug: '',
        uuid: ''
    });



// END of getting Category by UUID
// +===================================================================
    const getUrl = `${apiURL}startup_categories/get/`
    

    useEffect(() => {
        const getCategory = () => {
            return axios.post(getUrl, {'uuid':categoryId}, config)
                .then((response) => {
                    const { name,slug, uuid } = response.data;
                    setCategoryInfo({ name, slug, uuid });
                    setCategoryInfo(response.data)
                    // console.log(response.data);
                }).catch((error) => {
                    setErrorMessage(error.response.message)
                })
        };
         getCategory()
    }, [categoryId]);

// Update Category=======================================

    const handleChange = e => {
        const {name, value} = e.target
        setCategoryInfo({
          ...categoryInfo, 
          [name]:value
        })
      }

    const updateUrl = `${apiURL}startup_categories/update/`
    const handleUpdateCategory = async(e) => {
        e.preventDefault();
        setLoading(true)
        return axios.post(updateUrl, {uuid:categoryInfo.uuid, name: categoryInfo.name}, config)
            .then((response) => {
                //console.log(response.data);
                if (response.status === 200) {
                    alert("Category successfully updated");
                    //props.history.push("/categories");
                    navigate("/dashboard/categories")
                    setSuccessMessage("Category updated successfully")
                  }else Promise.reject();
            }).catch((error) => {
                console.log(error.response.data);
            })
        
    }









  
  return (
  <section>
    <div className="overview-title">
        <h1>Edit Startup Category</h1>
    </div>
    <div className="row">
       
        <div className="col-md-8 offset-1">
                <div className="startup-d-container">
                <div className="startup-d-header">
                    <div className="startup-header-info">
                        <p className="startup-category">Startup Category</p>
                        <h3 className="startup-name">Edit Category</h3>
                    </div>

                </div>
                <div className="startup-d-desc">
                <div className="contact-form">
                            <span className="text-success">{successMessage}</span>
                            <span className="text-danger">{errorMessage}</span>
                            <form action="#" method="post" onSubmit={handleUpdateCategory} className="row">
                                <div className="col-md-12">
                                    <label htmlFor="category">Startup category name</label>
                                    <input type="text" name="name"
                                    value={categoryInfo.name}
                                    onChange={handleChange}
                                    placeholder="Startup Category Name"/>
                                </div>
                                
                                <div className="col-md-12 text-right">
                                   
                                    <input type="submit" name="submit" value="Update Category"/>
                                </div>
                            </form>
                        </div>
          

                </div>
                <div className="startup-d-contact">
                    {/* <a href="/contact">Contact Startup <BsArrowRightShort/></a> */}
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default CategoryEditDetails