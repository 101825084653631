import AuthService, {apiURL, imgUrl} from "../../../utils/Common"
import axios from "axios";


const currentUser = AuthService.getCurrentUser()
 // get user ID and access token
 const token = currentUser?.access_token;

    // ====================
  //   using access token as authorisation for bearer to pass authenticated request for updates
  const config = {
    headers: { 
        "Authorization": `Token ${token}` 
    }
};


  // ====================SEND USER EMAIL VERIFICATION============================

      // CHECK IF USer email is verified then send message
   
    const verifyEmail = async() => {
        return axios.post(`${apiURL}send_verification_email/`, {'email':currentUser.email }, config)
                .then(response => {
                if (response.status === 200) {
                    alert('Please check your mailbox to complete the verification process')
                    }else Promise.reject()
                
                })
        }



const Ov = {
// Nothing here for now
}
export {Ov, config, verifyEmail, currentUser}