import React from 'react'
import './traffic.css'
import trafficImg from '../../assets/images/home/main-about.jpg'
import heroThumb2 from '../../assets/vid/st2.gif'

const Traffic = () => {
  return (
    <section className="appie-traffic-area pt-140 pb-180 animated DropIn">
        <div className="container">
            <div className="row">
                <div className="col-lg-7">
                    <div className="appie-traffic-title">
                        <span>Changing the <strong><b>Narrative</b></strong></span>
                        <h3 className="title">The Startups <strong><b>MarketPlace</b></strong>.</h3>
                        <p>Where possibilities meet technology.</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="appie-traffic-service mb-30">
                                <div className="icon">
                                    <i className="fal fa-check"></i>
                                </div>
                                <h5 className="title">Who we are</h5>
                                <p>We're an Africa based startup with focus on bringing to life all the diverse talents and 
                                    <strong>Ideas</strong> into Market-Ready Products.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="appie-traffic-service item-2 mb-30">
                                <div className="icon">
                                    <i className="fal fa-check"></i>
                                </div>
                                <h5 className="title">How we help</h5>
                                <p>We provide a space where all founders across Africa can conveniently 
                                    list their products for potential investors</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="appie-traffic-service item-3">
                                <div className="icon">
                                    <i className="fal fa-check"></i>
                                </div>
                                <h5 className="title">Where we focus</h5>
                                <p>
                                    We have as <strong>Primary focus to link startups to
                                         potential markets and Investors</strong>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="appie-traffic-service item-4">
                                <div className="icon">
                                    <i className="fal fa-check"></i>
                                </div>
                                <h5 className="title">What we believe in</h5>
                                <p>We believe Africa is diverse and well under represented. 
                                    We standout to be that platform that helps bring diverse <strong>Ideas</strong> and products to light</p>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="traffic-btn mt-50">
                                <a className="main-btn" href="/about">Learn More <i className="fal fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="traffic-thumb ">
        <img className="wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="200ms" src={heroThumb2} alt=""/>
            <img className="wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="200ms" src={trafficImg} alt=""/>
            
        </div>
    </section>
    
  )
}

export default Traffic