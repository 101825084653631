import React, {useState, useEffect} from 'react'
import startupLogo from '../../../../assets/images/blog/p3.jpg'
import {BsArrowRightShort} from 'react-icons/bs'
import AuthService, {apiURL} from '../../../../utils/Common'
import axios from "axios"
import { Link, useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { config } from '../../../dashboard/overview/Ov'
// import Spinner from 'react-bootstrap/Spinner';


const CategoryDetail = (props) => {

    const currentUser = AuthService.getCurrentUser()
    const[errorMessage, setErrorMessage] = useState("")
    const[successMessage, setSuccessMessage] = useState("")
    const [isloading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dloading, setDLoading] = useState(false);
    const [cloading, setCLoading] = useState(false);
    const [reload, setReload] = useState(0);

    const[startupCategory, setStartupCategory] = useState([]);
    const navigate = useNavigate()


 


    // =====================DELETE CATEGORY BY UUID =============================================
    const deleteUrl =`${apiURL}startup_categories/delete/`


    const handleDelete = async(index) => {
        setDLoading(true)
        return axios.post(deleteUrl, {'uuid':index} ,config)
            .then(response => {
                if (response.status === 200) {
                    alert("Category successfully Deleted");
                    // console.log(response.data);
                    setStartupCategory(response.data)
                    
                    setSuccessMessage("Category Deleted successfully")
                    setDLoading(false)
                    window.location.reload();
                  }else Promise.reject();
                
            return 
        }).catch(error => {
            setLoading(false)
            setErrorMessage(error.response.message)
        })
    }
 
// ===========================================================================
//  Get all categories
    


    useEffect(() => {
        const getUrl = apiURL + "startup_categories/list/"
        setLoading(true)
        const getCategorys = () => {
            setIsLoading(true)
            return axios.get(getUrl, config)
             .then(response => {
                //console.log(response.data);
                // console.log(response.data.data);
                setStartupCategory(response.data.data)
                setIsLoading(false)
              return 
            }).catch((error) => {
                setErrorMessage(error.response.message)
                setIsLoading(false)
            })
            
          };
          getCategorys()
      },[loading])
    //   if (loading) return <Spinner />;

    //   console.log(startupCategory);
    const listCategory = startupCategory.map((category, i) =>
    <tr key={category.uuid}>
        <th scope="row">{i}</th>
        <td>{category.name}</td>
        <td><Link to={`/dashboard/admin/setup/categories/edit/${category.uuid}`}
            // state={{from: category.name}}
             type="button" className="btn btn-info">Edit</Link></td>
        <td><button onClick={()=>handleDelete(category.uuid)} 
            type="button" className="btn btn-danger">{dloading ? <>Deleting...</> : <>Delete</>}</button></td>
    </tr>

    )

// ================================================================================
    // Create New Categories

    // console.log(getCategory());
    const createUrl = `${apiURL}startup_categories/create/`

    const [newCategory, setNewCategory] = useState({
        name: ""
      })
    const handleChange = e => {
        const {name, value} = e.target
        setNewCategory({
          ...newCategory, 
          [name]:value
        })
      }

  
    const createCategory = (e) => {
        e.preventDefault();
        // console.log(newCategory);
        setCLoading(true)
        axios.post(createUrl, newCategory, config)
            .then(response => {
               if (response.status === 200) {                         
                setSuccessMessage('Category Created Successfully')                      
                //window.location.reload();  
                setNewCategory(response.data) 
                setNewCategory({name: ''}) 
                setCLoading(false)
               }else Promise.reject();
            }).catch(error => {
                setErrorMessage(error.response.data.message)
            })
            


    }
  return (
    <section>
        <div className="overview-title">
            <h1>Category setup page</h1>
        </div>
        <div className="row">
            <div className="col-md-6">
                    <div className="startup-d-container">
                    <div className="startup-d-header">
                        <div className="startup-header-info">
                            <p className="startup-category">Fintech</p>
                            <h3 className="startup-name">All Categories</h3>
                        </div>

                    </div>
                    <div className="startup-d-desc">
                    <span className="text-success">{successMessage}</span>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Category Name</th>
                            <th scope="col">Edit</th>
                            <th scope="col">Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isloading ? 
                                <div className="loading">Loading...</div>
                            :
                                listCategory
                            }
                            
                        </tbody>
                        </table>

                    </div>
                    <div className="startup-d-contact">
                        <a href="/contact">Contact Startup <BsArrowRightShort/></a>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="startup-d-container">
                    <div className="startup-d-header">
                        <div className="startup-header-info">
                            <p className="startup-category">Startup Category</p>
                            <h3 className="startup-name">Create Category</h3>
                        </div>

                    </div>
                    <div className="startup-d-desc">
                        <div className="contact-form">                            
                            <span className="text-danger">{errorMessage}</span>
                            <form action="#" method="post" onSubmit={createCategory} className="row">
                                <div className="col-md-12">
                                    <label htmlFor="category">Startup category name</label>
                                    <input type="text" name="name" value={newCategory.name} 
                                     onChange={handleChange}
                                    placeholder="Startup Category Name"/>
                                </div>
                                
                                <div className="col-md-12 text-right">
                                   <button type="submit" className="btn btn-info">
                                        {cloading ? <>Creating New...</> : <>Create New Category</>}
                                        </button>
                                    {/* <input type="submit" name="submit" value="Create New Category"/> */}
                                </div>
                            </form>
                        </div>         
                    </div>
                    <div className="startup-d-contact">
                        {/* <a href="/contact">Contact Startup <BsArrowRightShort/></a> */}
                    </div>
                 </div>
            </div>
        </div>
    </section>
  )
}

export default CategoryDetail