import React, {useState, useEffect} from 'react'
import './overview.css'
// import OverviewCard from './OverviewCard'
import AuthService, {apiURL, imgUrl} from "../../../utils/Common"
import axios from 'axios'
import { Link } from 'react-router-dom'
// import {BsTools} from 'react-icons/bs'
import {BsArrowRightShort} from 'react-icons/bs'
import { OverviewNew } from './OverviewNew'
import { config, verifyEmail } from './Ov'
import { BiBookAlt } from 'react-icons/bi'


const Overview = ({userInfo, setUserInfo}) => {
 

  const currentUser = AuthService.getCurrentUser()
  const [startupData, setStartuprData] = useState([])
  const [startupMessage, setStartupMessage] = useState('')
  const [loading, setLoading] = useState(false);


    // get user ID and access token
 
    const authId = currentUser.user_uuid
    const isVerified = userInfo.email_verified
    // console.log(isVerified);


        // =============================USER ID
    // get user ID and access token
    const user_uuid = currentUser.user_uuid;
    // console.log(userRole);

  

    
      // const[userData, setUserData] = useState([]);
      const getUserUrl = `${apiURL}users/get/${user_uuid}/`
      useEffect(() => {
          const getUserData = async (user_uuid) => {
              return axios.get(getUserUrl, config)
                  .then((response) => {
                      if (response.status === 200) {
                      setUserInfo(response.data)
                      }else Promise.reject()
                  }).catch((error) => {
                      // setErrorMessage(error.response.message)
                  })
          }; getUserData()
      }, [user_uuid]);

    //====================GET ALL CATEGORIES=================
    const [catData, setCatData] = useState([])
    useEffect(() => {
        const getUrl = apiURL + "startup_categories/list/"
        const getCategory = () => {
            return axios.get(getUrl, config)
             .then(response => {
                setCatData(response.data.data)
              return 
            })
          };
          getCategory()
      },[loading])
  

  //============AUTH USER'S STARTUPS================================================ 
    const url = apiURL + "startups/list/"
    useEffect(() =>{
      const getUserStartupsByID = async () => {
          setLoading(true)
          return axios.get(url, config)
           .then(response => {
            if (response.status === 200) {
              setStartuprData(response.data.data)
              console.log(response.data.data);
              setLoading(false)
            }else Promise.reject()
            return 
          })
        };
        getUserStartupsByID()

    },[])
  

  return (
    <div>
        <section>
            { isVerified === false ?
                <p className="isVerifiedTT">
                  <Link to="">Please your email is not verified.  <button className='btn-danger' 
                  onClick={verifyEmail}>Click Here to Verify</button> </Link>
                </p>
              :
              <><br /></>
            } <br />
            <div className="overview-title">
                <h1>Overview - My Startup(s)</h1>
            </div>
            <div className="overview-container">
              {loading ?
                <div className="loading">Wait Loading ....</div>
                :
                <>
                  {startupData.length === 0 ?
                    <> No Startup Listed <br /> 
                      <Link to="/dashboard/startups/create/" className="startup-category">
                          <BiBookAlt  />List Startup
                      </Link>
                      
                    </>
                    :
                  
                    startupData.map((startup) => (<OverviewNew key={startup.startup_uuid} startup={startup} />)) 
                  }
                </>
              }
            </div>

            {/* <div className="overview-container">
                <OverviewCard/>
            </div> */}
        </section>
{/* =============================KYC REGISTRATION AND VALIDATION============================ */}
            <hr />
        <section>
          <div className="row">
              <div className="col-md-6 col-xs-12">
                      <div className="startup-d-container">
                      <div className="startup-d-header">
                          <div className="startup-header-info">
                              <p className="startup-category">KYC</p>
                              <h3 className="startup-name">Startup KYC Documents</h3>
                          </div>

                      </div>
                      <div className="startup-d-desc">
                      {/* <span className="text-success">{successMessage}</span> */}
                      <table className="table table-striped">
                          <thead>
                              <tr>
                              <th scope="col">#</th>
                              <th scope="col">Document Name</th>
                              <th scope="col">Edit</th>
                              <th scope="col">Delete</th>
                              </tr>
                          </thead>
                          <tbody>
                              
                          </tbody>
                          </table>

                      </div>
                      <div className="startup-d-contact">
                          <a href="/contact">Contact Startup <BsArrowRightShort/></a>
                      </div>
                  </div>
              </div>
              <div className="col-md-6 col-xs-12">
                      <div className="startup-d-container">
                      <div className="startup-d-header">
                          <div className="startup-header-info">
                              <p className="startup-category">Startup KYC</p>
                          </div>

                      </div>
                        <div className="startup-d-desc">
                            <div className="contact-form">                            
                              {/* <span className="text-danger">{errorMessage}</span> */}
                              <form action="#" method="post"  className="row">
                                  <div className="col-md-12">
                                      <label htmlFor="license">Registration License</label>
                                      <input type="file" name="name" 
                                      placeholder="registration_license"/>
                                  </div> <br />
                                  <div className="col-md-12">
                                      <label htmlFor="article">Article of Assocition</label>
                                      <input type="file" name="name" 
                                      placeholder="registration_license"/>
                                  </div> <br />
                                  <div className="col-md-12">
                                      <label htmlFor="taxpayer">Tax Payers Doc</label>
                                      <input type="file" name="name" 
                                      placeholder="registration_license"/>
                                  </div> 
                                  
                                  <div className="col-md-12 text-right">                                    
                                      <input type="submit" name="submit" value="Submit"/>
                                  </div>
                              </form>
                            </div>
                        </div>
                      <div className="startup-d-contact">
                          {/* <a href="/contact">Contact Startup <BsArrowRightShort/></a> */}
                      </div>
                  </div>
              </div>
          </div>
        </section>
    </div>
  )
}

export default Overview