import React, {useState, useEffect} from 'react'
import './overview.css'
import OverviewCard from './OverviewCard'
import AuthService, {apiURL, imgUrl} from "../../../utils/Common"
import axios from 'axios'
import { Link } from 'react-router-dom'
import {BsTools} from 'react-icons/bs'
import {BsArrowRightShort} from 'react-icons/bs'
import { config, currentUser } from './Ov'


const OverviewNew = ({startup}) => {

    const [category, setCategory] = useState({});
    const [stage, setStage] = useState({})
    const currentUser = AuthService.getCurrentUser()
    const [errorMessage, setErrorMessage] = useState('')





    useEffect(() => {
        const getUrl = `${apiURL}startup_categories/get/`

         axios.post(getUrl, {'uuid':startup?.category_uuid}, config)
                .then((response) => {
                    setCategory(response.data)
                    // console.log(response.data.data);
                }).catch((error) => {
                    setErrorMessage(error.response.message)
                })
                

    }, [startup.category_uuid]);
// =======Startup Development Stage=============
    useEffect(() => {
        const getStage = async () => {
            return axios.post(`${apiURL}startup_development_stage/get/`, {'uuid':startup.development_stage_uuid}, config)
                .then((response) => {
                    setStage(response.data)
                    // console.log(response.data);
                }).catch((error) => {
                    setErrorMessage(error.response.message)
                })
        };
         getStage()
    }, [startup.development_stage_uuid]);


    return (
    <div className='overview_card'>
        
        {/* {startup.catData.map((category) => category.uuid)}  */}
        <p className="startup-category" >{category.name} </p>/ 
        <p className="startup-category" >{stage.name}</p>
        <div className="overview_card_title">
            <span>
            
                {startup.startup_logo === null ?
                <BsTools className='overview-card-icon'/>
                :startup.startup_logo === "{}" ?
                    <BsTools className='overview-card-icon'/>
                :startup.startup_logo === "" ?
                    <BsTools className='overview-card-icon'/>
                :
                    <img src={imgUrl + startup.startup_logo} alt="" height="50" width="50" />
                }
                
            </span>
            <h2>{startup.startup_name}</h2>
        </div>
        <p >{startup.problem_your_solving.length > 150 ?
            `${startup.problem_your_solving.substring(0, 100)}...` : startup.problem_your_solving
            }</p>
        
     
         
        <div className="startup-d-contact">
        {startup.user_uuid === currentUser.user_uuid ?
            <>
                {startup.is_published === true ?
            <>
                
                    <Link className='card-readmore' to={`/dashboard/user/mystartup/edit/${startup.startup_uuid}`}>
                        <BsArrowRightShort/> UNPUBLISH
                    </Link>
                    {/* <a href="/contact">Contact Startup </a> */}
                
            </>
            :
            <>
               
                    <Link className='card-readmore' to={`/dashboard/user/mystartup/edit/${startup.startup_uuid}`}>
                        <BsArrowRightShort/> EDIT DRAFT
                    </Link>
                    {/* <a href="/contact">Contact Startup </a> */}
              
            </>
            }
            </>
         :

         <></>
        }
            <Link className='card-readmore' to={`/dashboard/startup/detail/${startup.startup_uuid}`}>
                <BsArrowRightShort/> View more
            </Link>
            {/* <a href="/contact">Contact Startup </a> */}
        </div>    
    </div>
    
  )
}

export {OverviewNew}