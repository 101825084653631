import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../components/footer/Footer';
import Navbar from '../components/navbar/Navbar'
import AuthFunc from '../utils/Auth'
import './login.css'


const Login = () => {
  
    const[email, setEmail] = useState('')
    const[password, setPassword] = useState('')
    const[errorMessage, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(false);


    const navigate = useNavigate();

       
    const onChangeUsername = (e) => {
      const username = e.target.value;
      setEmail(username);
    };
    const onChangePassword = (e) => {
      const password = e.target.value;
      setPassword(password);
    };


    // Handles Login
    const handleSubmitLogin =  (e) => {
        e.preventDefault();
        setLoading(true)
        AuthFunc.login(email, password).then(
          () => {
            sessionStorage.setItem("login", true);
            setLoading(false)
            navigate("/dashboard");
            
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            setLoading(false);
            setErrorMessage(resMessage);
          }
        );
        
    }


  return (
    
    <div>
      <Navbar/>
      
      <section className="login-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 login-bg">

                      <div className=" ">
                      <h4>Startups4Africa</h4>
                                {/* <p>Welcome, We are here for you. Login and Continue your amazing journey with Us.</p> */}
                      </div>
                    </div>
                    
                    <div className="col-md-4">
                    
                                {/* <p>Welcome, We are here for you. Login and Continue your amazing journey with Us.</p> */}
                        <div className="login-right contact-form">
                            <h4>Login</h4>
                            {/* <p>Integer at lorem eget diam facilisis lacinia ac id massa.</p> */}
                            <span className="text-danger">{errorMessage}</span>
                            <form action="#" method="post" className="row" onSubmit={handleSubmitLogin}  >
                                
                               
                                <div className="col-md-12">
                                    <input type="text" name="email" placeholder="Email Address"
                                    value={email}
                                    onChange={onChangeUsername}
                                    required
                                    
                                    />
                                </div>
                                <div className="col-md-12">
                                    <input type="password" name="password" placeholder="Password"
                                      value={password}
                                      onChange={onChangePassword}
                                      required
                                    />
                                </div>
                                
                                
                                
                                <div className="col-md-12 text-right">
                                    <button type="submit" className="btn btn-info">{loading ? <div className="" disabled>Logging In...</div> : <>Login</>}</button>
                                </div>
                            </form>
                            <div className="col-md-12 text-left">
                                    <a href="https://api.startups4africa.com/en/users/login/password_reset/" 
                                      target="_blank"
                                      className="text-primary text-decoration-underline">
                                      Forgot Password</a>
                            </div>
                            <hr /> 
                            <div className="text-center">OR</div>
                            <div className="col-md-12 text-right">
                                    <Link to="/register" className="btn btn-info btn-lg btn-block">Signup</Link>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
      </section>
      <Footer/>
    </div>
  )
}

export default Login