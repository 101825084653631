import React, {useState, useEffect} from 'react'
import './startupview.css'
import StartupCard from './StartupCard'
import AuthService, {apiURL, imgUrl} from "../../../utils/Common"
import axios from 'axios'
import { Link } from 'react-router-dom'
import {BsTools} from 'react-icons/bs'
import Investor from '../../../components/investor/Investor'
import { verifyEmail, config, currentUser} from '../overview/Ov'
import { OverviewNew } from '../overview/OverviewNew'

const StartupsOverview = ({userInfo, setUserInfo}) => {
 

    // const currentUser = AuthService.getCurrentUser()
    const [startupData, setStartuprData] = useState([])
    const [loading, setLoading] = useState(false);
    const[startupCategory, setStartupCategory] = useState([]);
    const isVerified = userInfo.email_verified
    const investor_uuid = userInfo.investor_uuid
    const [investor, setInvestor] = useState({})
  

        // Get investor
        const intUrl = `${apiURL}vc/get/`

      //  console.log(currentUser.investor_uuid);
      // if (currentUser.role === "vc") {

      // }
         
            const getInvestor = async () => {
              return axios.post(intUrl, {'investor_uuid':currentUser.investor_uuid}, config)
                  .then(response => {
                      // console.log(response.data);
                      setInvestor(response.data)
                      return
                  }).catch(error => {
                    if (error.status === 500) {
                      console.log('error  caused by lazy load');
                    }
                      // console.log(error.response.message);
                  })
            }
      
      
        


          // =====================DELETE CATEGORY BY UUID =============================================
    const getCatIdUrl =`${apiURL}startup_categories/get/`
    
      const url = apiURL + "startups/list/"
      

      useEffect(() =>{
  
        const getStartups = async () => {
            setLoading(true)
            return axios.get(url, config)
            .then(response => {
              setStartuprData(response.data.data)
                // console.log(response.data.data);
                setLoading(false)
              return 
            })
          };
          getStartups()

      },[])
  




  return (
    <div>
        <section>
          { isVerified === false ?
                <>
                <div className="isVerifiedTT">
                  <Link to="">Please your email is not verified.  <button className='btn-danger' 
                  onClick={verifyEmail}>Click Here to Verify</button> </Link>
                  <Investor userInfo={userInfo} setUserInfo={setUserInfo} 
                  investor={investor} setInvestor={setInvestor} />
                </div>
                
                </>
                
              :
              <h6><hr /></h6>
            }
            <div className="overview-title">
                <h1>All Startups</h1>
            </div>
            <div className="overview-container">
        {/* List Startups */}
            {isVerified === false ?
              <div className='isVerified'>
                You have to verify your email to see the list of startups
              </div>
              :
              <>
                {loading ? 
                  <div className="loading">Wait Loading...</div>
                  :
                  startupData.map((startup) => (<OverviewNew key={startup.startup_uuid} startup={startup} />))
                }
              </>
            }
            </div>

            {/* <div className="overview-container">
                <OverviewCard/>
            </div> */}
        </section>
    </div>
  )
}

export default StartupsOverview