import axios from 'axios'
export   const apiURL = "https://api.startups4africa.com/api/";
export const imgUrl = "https://api.startups4africa.com/media/"


//\\\\\\\\my codee---kasi

const register = (firstname, lastname, email, password) => {
    return axios.post(apiURL + "signup/", {
      firstname,
      lastname,
      email,
      password,
    });
  };

  const login = async (email, password) => {
    const response = await axios
          .post(apiURL + "login/", {
              email,
              password,
          });
      if (response.data.email) {
          sessionStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
  };

  const logout = () => {
    sessionStorage.removeItem("user");
    sessionStorage.clear();
    return axios.get(apiURL + "logout/").then((response) => {
      console.log(response);
      return response.data;
    });
    // return
  };

  export const getCurrentUser = () => {
    return JSON.parse(sessionStorage.getItem("user"));
  };



  const AuthService = {
    register,
    login,
    logout,
    getCurrentUser
  }

 

  export default AuthService;


// ==============================================
// Old code

// export const getUser = (access_token) => {
//   axios.get(`${apiURL}/users/get/${access_token}`)
//   .then(res => console.log(res.data));


//   const useStr = sessionStorage.getItem('access_token');
//   if(useStr) return JSON.parse(useStr);
//   else return null;
// }

// // get authenticated user token
// export const getToken = () => {
//   return JSON.parse(sessionStorage.getItem("access_token")) || null
// }

// // Set Auth User's session
// export const setUserSession = (access_token, user_uuid) => {
//   sessionStorage.setItem("access_token", JSON.stringify(access_token));
//   // sessionStorage.setItem("user_uuid", JSON.stringify(user_uuid));
// }

// // Finally destroy or remove the session on logout
// export const removeUserSession = () => {
//   sessionStorage.removeItem("access_token");
//   sessionStorage.clear();
// }