import React from 'react'
import './team.css'
import teamImg2 from '../../assets/images/home/team.jpg'

const Team = () => {
  return (
    
    <section className="appie-team-area pt-90 pb-100">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="appie-section-title text-center">
                        <h3 className="appie-title">Meet our Team Members</h3>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="appie-team-item mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div className="thumb">
                            <img src={teamImg2} alt=""/>
                            <ul>
                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i className="fab fa-pinterest-p"></i></a></li>
                            </ul>
                        </div>
                        <div className="content text-center">
                            <h5 className="title">Njuakom Chambah</h5>
                            <span>CEO-Founder</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="appie-team-item mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div className="thumb">
                            <img src={teamImg2} alt=""/>
                            <ul>
                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i className="fab fa-pinterest-p"></i></a></li>
                            </ul>
                        </div>
                        <div className="content text-center">
                            <h5 className="title">Chiambah Endurance</h5>
                            <span>Backend Developer</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="appie-team-item mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div className="thumb">
                            <img src={teamImg2} alt=""/>
                            <ul>
                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i className="fab fa-pinterest-p"></i></a></li>
                            </ul>
                        </div>
                        <div className="content text-center">
                            <h5 className="title">Casy Azmon</h5>
                            <span>CTO</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="appie-team-item mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="800ms">
                        <div className="thumb">
                            <img src={teamImg2} alt=""/>
                            <ul>
                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i className="fab fa-pinterest-p"></i></a></li>
                            </ul>
                        </div>
                        <div className="content text-center">
                            <h5 className="title">Carlson Evalent</h5>
                            <span>Mobile Dev</span>
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-12">
                    <div className="team-btn text-center mt-50">
                        <a className="main-btn" href="#"> View all Members <i className="fal fa-arrow-right"></i></a>
                    </div>
                </div> */}
            </div>
        </div>
    </section>
  )
}

export default Team