import React, { useState, useEffect } from 'react'
import startupLogo from '../../../../assets/images/blog/p3.jpg'
import {BsArrowRightShort} from 'react-icons/bs'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import AuthService, {apiURL} from '../../../../utils/Common'
import { Link, useParams, useNavigate } from 'react-router-dom'
import axios from 'axios';
import ImageUploading from 'react-images-uploading';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// const apiURL = "https://startups4africa.237votes.com/api/";

const UserEditDetail = ({index}, props) => {
    const {id} = useParams()
    const userId = {id}.id

    console.log(userId);


    const navigate = useNavigate()
    const currentUser = AuthService.getCurrentUser()
    const [userInfo, setUserInfo] = useState({
        first_name:'',
        last_name:'',
        role:''
    });
    const[errorMessage, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(false);
    const[successMessage, setSuccessMessage] = useState("")

    const [phoneNumber, setPhoneNumber] = useState()
    // const [value, setValue] = useState()
    const [startDate, setStartDate] = useState(new Date());

    // const [userImage, setUserImages] = React.useState({
    //     picture: false,
    //     src: false
    // });

    // ============== Authorisation Header with Token ==============================

    // get user ID and access token
    const user_uuid = currentUser.user_uuid;
    const token = currentUser.access_token;

    //   using access token as authorisation for bearer to pass authenticated request for updates
    const config = {
    headers: { 
        "Authorization": `Token ${token}` 
    }
    };


// =======================Get User BY ID ==================================
    // const[userData, setUserData] = useState([]);
    const getUserUrl = `${apiURL}users/get/${userId}/`
    useEffect(() => {
        const getUserData = (userId) => {
            return axios.get(getUserUrl, config)
                .then((response) => {
                    const { first_name,last_name, role } = response.data;
                    setUserInfo(response.data)
                    setUserInfo({ first_name, last_name, role });
                    console.log(response.data);
                }).catch((error) => {
                    // setErrorMessage(error.response.message)
                })
        }; getUserData()
    }, [userId]);
    


      
    

    function formatDate(newDate) {
        //var newDate = new Date();
        var month = newDate.getUTCMonth() + 1; //months from 1-12
        var day = newDate.getUTCDate();
        var year = newDate.getUTCFullYear();

        var newdate = year + "-" + month + "-" + day;
        return newdate;
        
    }
    

    console.log(formatDate(startDate));
    console.log(phoneNumber);

    const params = useParams();
    // console.log(userInfo);

    const handleChange = e => {
        const {name, value} = e.target
        setUserInfo({
          ...userInfo,
          [name]:value
        })
      }

    const url = `${apiURL}users/update/${userId}/`

    const handleAdminProfileUpdate = (e) => {
        e.preventDefault()

        setLoading(true)
        console.log(userInfo);
        axios.post(url, userInfo, config)
            .then(response => {
                if (response.status === 200) {
                    alert("User successfully updated");
                    //props.history.push("/categories");
                    console.log(response.data);
                    navigate("/dashboard/users")
                    setSuccessMessage("User updated successfully")
                  }else Promise.reject();
                // uploadImage()
                setLoading(false)
                console.log(response.data);
            }).catch(error => {
                setErrorMessage(error.response.data.message)
            })
    }


  return (
    <section>
    <div className="overview-title">
        <h1>Admin Edit User Profile</h1>
    </div>
    <Link to="/dashboard/users/" className="startup-category">BACK TO USERS</Link>
    <div className="row">
        <div className="col-md-8 offset-2">
            <div className="startup-d-container">
                <div className="startup-d-header">
                
                    <div className="startup-header-info">
                    {/* <p><Link to="/dashboard/users/" className="startup-category">BACK TO USERS</Link></p> <br /> */}
                        <p className="startup-category">Edit My Profile</p> <br />
                        <p className="startup-category">{userInfo.name}</p>
                    </div>

                </div>
                <div className="contact-form">
                            <span className="text-danger">{errorMessage}</span>
                            <form action="#" method="post" onSubmit={handleAdminProfileUpdate} className="row">
                                <div className="col-md-6">
                                    <label htmlFor="first_name">First Name</label>
                                    <input type="text" name="first_name" 
                                    value={userInfo.first_name} 
                                    onChange={handleChange}
                                    placeholder="First Name"/>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="last_name">Last Name</label>
                                    <input type="text" name="last_name" 
                                    value={userInfo.last_name}  
                                        onChange={handleChange} placeholder="Last Name"/>
                                </div>
                                <div className="col-md-12 form-select">
                                    <select name="role" value={userInfo.role} 
                                        className="signup" 
                                        onChange={handleChange}>
                                        <option></option>
                                        <option>startup</option>
                                        <option>vc</option>
                                        <option>admin</option>
                                    </select>
                                </div>
                                {/* <div className="col-md-12">
                                    <label htmlFor="phoneNumber">Phone Number</label>
                                    <PhoneInput className='phonetype' name="phone"
                                        international
                                        defaultCountry="CM"
                                        placeholder="Enter phone number"
                                        value={phoneNumber}
                                        onChange={setPhoneNumber}
                                        // onChange={handleChange}
                                        />
                                </div> 
                                <div className="col-md-12">
                                    <label htmlFor="date">Date of Birth</label>
                                    <DatePicker className="dob" 
                                        name="date_of_birth"
                                        dateFormat="yyyy-MM-dd"
                                        value={userInfo.date_of_birth}
                                        selected={startDate} 
                                        onChange={(date) => setStartDate(date)}
                                          />
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="country">Country</label>
                                    <input type="text" name="country" 
                                    // value={userInfo.country}  
                                        onChange={handleChange} placeholder="Country"/>
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="userImage">Upload Image</label>
                                    <input type="file" name="userImage"   
                                        onChange={onImageChange} placeholder="Country"/>
                                </div>
                                {userImage.src && <img style={{width:"250px", height:"auto"}} src={userImage.src} alt="user profile" />}
                               
                                <div className="col-md-12">
                                <label htmlFor="about_me">About Me</label>
                                    <textarea name="about_me" 
                                    value={currentUser.about_me}  
                                        onChange={handleChange} placeholder="My Bio?">
                                    
                                    </textarea>
                                </div> */}
                                <div className="col-md-12 text-right">
                                   
                                    <input type="submit" name="submit" value="Update Profile"/>
                                </div>
                            </form>
                        </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default UserEditDetail