import React, {useState} from 'react'
import './settings.css'
import SettingsDetail from './SettingsDetail'
import DashboardSidebar from '../../../components/dashboard/DashboardSidebar'
import DashbordNavbar from '../../../components/dashboard/DashbordNavbar'
import AuthService from '../../../utils/Common'

const Settings = () => {
  const currentUser = AuthService.getCurrentUser()
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(currentUser);
  // console.log(userInfo);
  return (
    <div className='admin-dashboard'>
        <DashbordNavbar/>
        <DashboardSidebar/>
        <div className="dashboard-main">
        <SettingsDetail userInfo={userInfo} setUserInfo={setUserInfo}/>

      </div>
    </div>
  )
}

export default Settings