import React from 'react'
import ErrorImg from '../assets/images/error.png'
import Footer from '../components/footer/Footer'
import Navbar from '../components/navbar/Navbar'

const Error = () => {
  return (
    <div>
        <Navbar/>
        <div className="appie-error-area mb-60">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-error-content text-center">
                            <img src={ErrorImg} alt=""/>
                            <span>Sorry!</span>
                            <h3 className="title">The page can’t be found.</h3>
                            <p>The page you're looking for isn't available. Try with another page or use the go home button below</p>
                            <a href="/">Back to Home <i className="fal fa-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Error