import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

const ProtectedRoute = ({children, user}) => {
 
     const loginvalue = sessionStorage.getItem("login")
     if (loginvalue) {
        user = true;
       }
       
  if(!user){
    return <Navigate to='/login'/>;
  }
  return children;
}

export default ProtectedRoute