import React, {useEffect, useState} from 'react'

import {BsArrowRightShort} from 'react-icons/bs'
import AuthService, {apiURL, imgUrl} from '../../../utils/Common'
import axios from "axios"
import {BsTools} from 'react-icons/bs'
import {useParams, Link, useNavigate} from "react-router-dom";
import { StartupCard } from './StartupCard'
import { config } from './Ov'


const StartupDetailInfo = ({index}, props) => {
    const {id} = useParams(index);
    const isStartupID = {id}.id
    // console.log(isStartupID);
    const[errorMessage, setErrorMessage] = useState("")
    const[isStartup, setIsStartup] = useState([])
    const currentUser = AuthService.getCurrentUser()
    const[categoryId, setCategoryId] = useState([])
    const[devId, setDevId] = useState([])
    const[raiseId, setRaiseId] = useState([])
    const[vcStartups, setVcStartup] = useState([])
    const [loading, setLoading] = useState('')
    // -----------------------------------------------------------------
    // console.log(`Startup Id: ${isStartup.category_uuid}`);
    // console.log(devId.name);


// ================GET STARTUP  BY ID=========================================
const getStartupUrl = apiURL + "startups/get/"
useEffect(() =>{

    const getStartupByID = () => {
        return axios.post(getStartupUrl, {'startup_uuid':isStartupID}, config)
        .then(response => {
            if (response.status === 200) {
                setIsStartup(response.data)
                // console.log(response.data);
            }else Promise.reject()
            return 
        })
        };
        getStartupByID()

},[])
    // ===================GET CATEGORY=========================
      const getCategoryUrl = apiURL + "startup_categories/get/"

      useEffect(() => {
        const getCategoryByID = async () => {
            return axios.post(getCategoryUrl, {'uuid':isStartup.category_uuid}, config)
                .then(response => {
                    if (response.status === 200) {
                        setCategoryId(response.data)
                        // console.log(response.data);
                    }else Promise.reject()
                    return 
                })
        };
        getCategoryByID()
      }, [isStartup.category_uuid])

  
// ================DEV STAGE ====================================
        const getDevUrl = apiURL + "startup_development_stage/get/"

        useEffect(() => {
            const getDevStageById = async () => {
                return axios.post(getDevUrl, {'uuid':isStartup.development_stage_uuid}, config)
                  .then(response => {
                      if (response.status === 200) {
                          setDevId(response.data)
                          // console.log(response.data);
                      }else Promise.reject()
                      return 
                  })
                };
                getDevStageById()
        }, [isStartup.development_stage_uuid])

       

// ============RAISING PHASE=======================
// ================DEV STAGE ====================================
    const getRaiseUrl = apiURL + "startup_raising_phase/get/"

    useEffect(() => {
        const getRaiseId = async (raiseId) => {
            return axios.post(getRaiseUrl, {'uuid':isStartup.raising_phase_uuid}, config)
                .then(response => {
                    if (response.status === 200) {
                        setRaiseId(response.data)
                        // console.log(response.data);
                    }else Promise.reject()
                    return 
                })
            };
            getRaiseId()
    }, [isStartup.raising_phase_uuid])
    


// =============================VC CONNECT WITH STARTUP====================================
    const followUrl = `apiURL + vc/connect/ +${isStartup.startup_uuid}/`
    const connectWithStartup = async () => {
        return axios.get(followUrl, config)
            .then(response => {
            if (response.status === 200) {
                setVcStartup(response.data)
                // console.log(response.data);
            }else Promise.reject()
            return 
        }).catch(error => {
            setErrorMessage(error.response.message)
            alert(errorMessage)
        })
    };

    // ====================DELETE STARTUP===
    const navigate = useNavigate()
    const deleteUrl = apiURL + "startups/delete/"
    const handleDeleteStartup = async(index) => {
        setLoading(true)
        return axios.post(deleteUrl, {'startup_uuid':index}, config)
            .then(res => {
                // console.log(res.data);
                setLoading(false)
                navigate("/dashboard/")
            }).catch(error => {
                // console.log(error.res.data);
                setLoading(false)
            })
    }

  return (
  
        <section>
            <div className="overview-title">
                <h1>Startup: {isStartup.startup_name}</h1>
            </div>
            {currentUser.role === 'vc' ?
            <div className="startup-d-contact">
                    <button className="btn btn-info" href="/contact">Connect With Startup <BsArrowRightShort/></button>
            </div>
            :
            <></>

            }
          
            
           
            <div className="startup-d-container col-md-12 col-xs-12">
                <div className="startup-d-header">
                    <div className="startup-header-logo">
                        {isStartup.startup_logo === null ?
                            <BsTools className='overview-card-icon'/>
                            :isStartup.startup_logo === "{}" ?
                              <BsTools className='overview-card-icon'/>
                            :isStartup.startup_logo === "" ?
                              <BsTools className='overview-card-icon'/>
                            :
                              <img src={imgUrl + isStartup.startup_logo} alt="" height="50" width="50" />
                          }
                        {/* <img src={imgUrl + isStartup.startup_logo}  /> */}
                    </div>
                    
                    <div className="startup-header-info">
                        <p className="startup-category">
                            {categoryId.name}
                        </p>
                        <h3 className="startup-name">Startup Name: {isStartup.startup_name}</h3>
                        <p className="startup-founder-date">{isStartup.founders_name} | {isStartup.established_in}</p>
                    </div>
                    { isStartup.pitch_deck_file === null ?
                        <></>
                    : isStartup.pitch_deck_file === "" ?
                        <></>
                    :
                        <div className="startup-d-contact text-right">

                            <a className='btn btn-info' href={imgUrl + isStartup.pitch_deck_file} target="_blanck">
                            <BsArrowRightShort/> View Pitch Deck</a>
                        </div>

                    }
                </div>

                <div className="startup-d-desc">
                    <hr />
                    <h5>About Startup</h5> <hr />
                    <p>Startup Address: <b>{isStartup.enter_full_address}</b></p> <br />
                    <p>Country: <b>{isStartup.country}</b> || 
                        Region: <b>{isStartup.region}</b> || City: <b>{isStartup.city}</b></p> <br />
                    <p>Country of Incorporation: <b>{isStartup.country_of_incorporation}</b> || 
                    Registered Date: <b>{isStartup.established_in}</b> </p> <br />
                    
                    <p>Sectory of Activity: {categoryId.name? categoryId.name: "null"} </p> <br />
                    <p>Development Stage: {devId.name? devId.name: "null"}</p><br />
                    <p>Raising Phase: {raiseId.name? raiseId.name: "null"}</p><br />
                    <hr />
                    <h5>Startup Contact Information</h5>
                    <p>Website: {isStartup.startup_website}</p><br />
                    <p>Twitter Profile: {isStartup.twitter_profile}</p><br />
                    <p>LinkedIn Profie: {isStartup.linkedin_profile}</p><br />
                    <p>Contact Person: {isStartup.contact_name}</p><br />
                    <p>Contact Email: {isStartup.contact_email}</p> <br />
                    <p>Contact Phone Number: {isStartup.contact_phone_number}</p>
                    <hr />
                    <h5>Startup Analysis</h5>
                    <p>Founder: <b>{isStartup.founders_name}</b></p> <br />
                    <p>Founder & Team Background: <br /> <b>{isStartup.founders_background}</b></p> <br />
                    <p>Problem You're Solving: <br />
                        <b>{isStartup.problem_your_solving}</b>
                    </p> <br />
                    <p className="startup-d-desc">Solution: <br />
                        <b>{isStartup.solution}</b>
                    </p> <br />
                    <div className="startup-d-desc">
                        <p>What is the startup's unique selling proposition (USP) to the market?: <br />
                            <b>{isStartup.usp_market}</b>
                        </p>
                    </div> <br />

                    <div className="startup-d-desc">
                        <p>How does your business make money (What's your business model)?: <br />
                            <b>{isStartup.business_model }</b>
                        </p>
                    </div> <br />
                    <div className="startup-d-desc">
                        <p>How many Full-Time Employees (FTE) does the startup have?: <br />
                            <b>{isStartup.fulltime_employees }</b>
                        </p>
                    </div> <br />
                    <div className="startup-d-desc">
                        <p>Which Sustainable Development Goals (SDG) does your company contribute to (i.e. 1 - 17)?: <br />
                            <b>{isStartup.sdg_goals }</b>
                        </p>
                    </div> <br />
                    <div className="startup-d-desc">
                        <p>Which are the main SDG impact areas your company focuses on?: <br />
                            <b>{isStartup.sgd_focus_goal }</b>
                        </p>
                    </div> <br />
                    <div className="startup-d-desc">
                        <p>What percentage is owned by you: <br />
                            <b>{isStartup.percentage_owned }</b>
                        </p>
                    </div> <br />
                    <div className="startup-d-desc">
                        <p>How much equity is owned by other main shareholder besides founder(s), who are they and what percentages do they own?: <br />
                            <b>{isStartup.equity_not_owned_by_you }</b>
                        </p>
                    </div> <br />
                    <div className="startup-d-desc">
                        <p>What is the total value of Angel investment received by the Startup?: <br />
                            <b>{isStartup.received_funds_before }</b>
                        </p>
                    </div> <br />
                    <div className="startup-d-desc">
                        <p>When was the investment from the Angel investor(s) received by the Startup?<br />
                            <b>{isStartup.investment_from_angel_year }</b>
                        </p>
                    </div> <br />
                    <div className="startup-d-desc">
                        <p>What was the latest funding (MM-YY / Type / Amount ($) / Organisation) received by the Startup (if not the Angel investment)?<br />
                            <b>{isStartup.latest_funding_received }</b>
                        </p>
                    </div> <br />
                    <div className="startup-d-desc">
                        <p>What is the startups monthly revenue for the last 12 months?<br />
                            <b>{isStartup.startup_monthly_revenue }</b>
                        </p> 
                    </div> <br />
                    <div className="startup-d-desc">
                        <p>How much is the startup spending each month (i.e. Cash Burn Rate)<br />
                            <b>{isStartup.cash_burnt_rate }</b>
                        </p>
                    </div> <br />
                    <div className="startup-d-desc">
                        <p>How much cash does the startup have in the bank?<br />
                            <b>{isStartup.cash_available }</b>
                        </p>
                    </div> <br />
                    <div className="startup-d-desc">
                        <p>How many paying customers does the startup have?<br />
                            <b>{isStartup.paying_customers }</b>
                        </p>
                    </div> <br />
                    <div className="startup-d-desc">
                        <p>What was the ($) valuation (or Cap if SAFE or Convertible) for the startup for the Angel investment?<br />
                            <b>{isStartup.valuation_cap }</b>
                        </p>
                    </div> <br />
                    <div className="startup-d-desc">
                        <p>If different, what is the startup's latest valuation ($)?<br />
                            <b>{isStartup.latest_valuation }</b>
                        </p>
                    </div> <br />
                    <div className="startup-d-desc">
                        <p>Pitch Deck Url:<br />
                            <b>{isStartup.pitch_deck }</b>
                        </p>
                    </div>
                </div>
                <hr /> 
                {currentUser.role === "vc" ?
                    <div className="startup-d-contact">
                        <button className="btn btn-info" onClick={()=>connectWithStartup(isStartup.startup_uuid)}>
                            Connect With Startup <BsArrowRightShort/>
                        </button>
                    </div>
                : 
                <></>
                }

                {isStartup.user_uuid === currentUser.user_uuid ?
                    <>
                        <div className="startup-d-contact">
                            <Link className="btn btn-info" to={`/dashboard/user/mystartup/edit/${isStartup.startup_uuid}`}>
                                Edit Startup <BsArrowRightShort/>
                            </Link>
                        </div>
                        <div className="col-md-12 text-right">
                            {/* <input type="submit" name="submit" value="Register"
                            /> */}
                            <button type="submit" className="btn btn-danger" 
                                onClick={() => handleDeleteStartup(isStartup.startup_uuid)}>
                                {loading ? <>Processing...</> : <>Delete</>}</button>
                        </div>
                    </>
                 :

                 <></>

                }
               
                    
            </div>
        </section>
  )
}

export default StartupDetailInfo