import React, { useState, useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../components/footer/Footer';
import Navbar from '../components/navbar/Navbar'
import axios from 'axios';
import './signup.css'
import {apiURL} from "../utils/Common";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3'


const SignUp = () => {

  const navigate = useNavigate();
  const[errorMessage, setErrorMessage] = useState("")
  // const[isSubmitted, setIsSubmitted] = useState(false)
  const [loading, setLoading] = useState(false);

  
  // const [first_name, setFirstname] = useState('')
  // const [last_name, setLastname] = useState('')
  // const [email, setEmail] = useState('')
  // const [password, setPassword] = useState('')
  // const [confirm_password, setConfirmPassword] = useState('')
  const [newUser, setNewUser] = useState({
    first_name: "",
    last_name: "",
    role:"",
    email:"",
    password:"",
    confirm_password:""
  })
  
  // Handles Google ReCaptcha authentication
  const [token, setToken] = useState();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const onVerify = useCallback((token) => {
    setToken(token);
  });

// Hanfle form value change
  const handleChange = e => {
    const {name, value} = e.target
    setNewUser({
      ...newUser, 
      [name]:value
    })
  }


  const handleSubmitRegister =  (e) => {
      e.preventDefault();
      // const data = {
      //   email:"bahd@bahd.com",
      //   first_name:"bahd",
      //   last_name:"champs",      
      //   password:"123456"
      // }
  
      if(!newUser.first_name || !newUser.last_name || !newUser.email 
            || !newUser.password || !newUser.confirm_password || !newUser.role) {
        return;
      } else if(newUser.password !== newUser.confirm_password) {
        setErrorMessage("Password and Confirm Password Must be the same")
        return;

      } else {
        setLoading(true)
        console.log(newUser)
        axios.post(`${apiURL}signup/`, newUser)
          .then(res => {
            setLoading(false)
            console.log(res)
            // setRefreshReCaptcha(r => !r);
            navigate("/login")
          }).catch(error => {
            setErrorMessage(error.response.data.message)
          })
          return

        
      }
      //  console.log(`email: ${email}, first_name: ${first_name} , password: ${password}`);
      
  }


  return (
    <div>
      <Navbar/>
      
      <section className="register-section ">
            <div className="container signup-bg">
                <div className="row">
                <div className="col-md-6">
                  <div className="">
                      <h4>Startups4Africa</h4>
                      
                  </div>
                </div>
                    
                <div className="col-md-6">
                    <div className="register-right contact-form">
                        <h4>Begin This Ultimate Journey with Us</h4>
                        <p>Welcome, We are excited to begin this journey with you today. 
                          Register and have ana amazing experience.</p>

                      <GoogleReCaptchaProvider reCaptchaKey="6LdjOk8hAAAAAFMd1ZI4mWnzZiyK9QVfRDB2wrL0">
                        <span className="text-danger">{errorMessage}</span>
                        <form action="#" id="signupForm" method="post" 
                                className="row" onSubmit={handleSubmitRegister}  >


                            <div className="col-md-6">
                              <input type="text" name="first_name" placeholder="First Name" 
                              value={newUser.first_name}
                              onChange={handleChange}
                              required />
                            </div>

                            <div className="col-md-6">
                              <input type="text" name="last_name" placeholder="Last Name" 
                              value={newUser.last_name}
                              onChange={handleChange} 
                              required />
                            </div>

                            <div className="col-md-12">
                              <select name="role" value={newUser.role} className="signup" 
                                      onChange={handleChange} required placeholder='select role'>
                                <option>Choose Role</option>
                                <option value="startup">Startups</option>
                                <option value="vc">Investor</option>
                              </select>
                            </div>
                            
                            
                            <div className="col-md-12">
                                <input type="email" name="email" placeholder="Email Address"
                                value={newUser.email}
                                onChange={handleChange}
                                required
                                
                                />
                            </div>
                            <div className="col-md-6">
                                <input type="password" name="password" placeholder="Password"
                                  value={newUser.password}
                                  onChange={handleChange}
                                  required
                                />
                            </div>

                            <div className="col-md-6">
                                <input type="password" name="confirm_password" placeholder="Confirm Password"
                                  value={newUser.confirm_password}
                                  onChange={handleChange}
                                  required
                                />
                            </div>

                            {/* <div className="col-md-12">
                                <div className="condition-check">
                                    <input id="terms-conditions" type="checkbox"/>
                                    <label for="terms-conditions">
                                        I agree to the <a href="#">Terms & Conditions</a>      
                                    </label>
                                </div>
                            </div> */}
                            
                            
                
                            <div className="col-md-12 text-right">
                                {/* <input type="submit" name="submit" value="Register"
                                /> */}
                                <button type="submit" className="btn btn-info">{loading ? <>Processing...</> : <>Register</>}</button>
                            </div>
                            <GoogleReCaptcha
                                onVerify={onVerify}
                                refreshReCaptcha={refreshReCaptcha}
                              />
                        </form>
                      </GoogleReCaptchaProvider>
                        <hr /> 
                            <div className="text-center">OR</div>
                            <div className="col-md-12 text-right">
                                    <Link to="/login" className="btn btn-info btn-lg btn-block">Login</Link>
                            </div>
                    </div>
                </div>
              </div>
                
            </div>
            
      </section>
      <Footer/>
    </div>
  )
}

export default SignUp