import { useState, useEffect } from "react";
import axios from "axios";
import { apiURL } from "../../../utils/Common";
import { config } from "../overview/Ov";


const VcCat = ({category}) => {
    const [cateData, setCatData] = useState({})

    // ======get category array==========
    useEffect(() => {

         axios.post(`${apiURL}startup_categories/get/`, {'uuid':category.category_uuid}, config)
                .then((response) => {
                    setCatData(response.data)
                    // console.log(response.data);
                }).catch((error) => {
                    console.log(error.response.message)
                })
                
                
    }, [category.category_uuid]);
    // console.log(category);
  

    return(
        <>
        <p className="startup-category" >{cateData.name} </p>
        </>
    )
}
export {VcCat}