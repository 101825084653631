import React, {useState} from 'react'
import './dashboardnavbar.css'
import {MdOutlineNotificationsNone} from 'react-icons/md'
import {RiSettings4Line} from 'react-icons/ri'
import userProfileImg from '../../assets/images/blog/p3.jpg'
import startupNoImage from '../../assets/images/blog/programmer.png'
import {FaSearch} from 'react-icons/fa'
import AuthService, {apiURL, imgUrl} from '../../utils/Common'
import { Link, Navigate } from 'react-router-dom'

const DashbordNavbar = () => {
    const currentUser = AuthService.getCurrentUser();
    const [userInfo, setUserInfo] = useState(currentUser);
    const profileImg = imgUrl + currentUser.photo
    
  return (
    <div className='dashboardNavbar'>
        <div className="dashboardNavbar-container">
            <div className="dashboradNavbar-search">
                <input type="text" 
                    placeholder='Search...'
                    className='navbar-search-form'
                />
                <FaSearch className='navbar-search-icon'/>


            </div>
            <div className="dashboardNavbar-links">
                <a href="#"><MdOutlineNotificationsNone className='navbar-icons'/></a>
                <li href="#">
                    <Link to="/dashboard/settings/">
                        <RiSettings4Line className='navbar-icons'/>
                    </Link>
                </li>
                <li href="#" className="topName">
                    <Link to="/dashboard/settings/">
                        {currentUser.name}
                    </Link>
                </li>
                {profileImg == " " && profileImg === null ?
                    <img src='/src/assets/images/blog/programmer.png' alt="" />

                    :

                    <img src={profileImg} alt="Profile img" />
                }

            </div>

        </div>
        
    </div>
  )
}

export default DashbordNavbar