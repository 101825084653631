import React from 'react'
import './features.css'

import FeatureImg1 from '../../assets/images/home/main.jpg'
import FeatureImg2 from '../../assets/images/home/market.jpg'
import FeatureImg3 from '../../assets/images/home/raising.jpg'
import FeatureImg4 from '../../assets/images/home/product.jpg'

import ShapeImg6 from '../../assets/images/shape/shape-6.png'
import ShapeImg7 from '../../assets/images/shape/shape-7.png'
import ShapeImg8 from '../../assets/images/shape/shape-8.png'



const Features = () => {
  return (
    <section className="appie-features-area pt-100" id="features">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-3">
                    <div className="appie-features-tabs-btn">
                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a className="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" 
                                role="tab" aria-controls="v-pills-home" 
                                aria-selected="true"><i className="fas fa-cog"></i> Main Objective</a>
                            <a className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" 
                                role="tab" aria-controls="v-pills-profile" 
                                aria-selected="false"><i className="fas fa-exclamation-triangle"></i> Market Readiness</a>
                            <a className="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" 
                                role="tab" aria-controls="v-pills-messages" 
                                aria-selected="false"><i className="fas fa-bell"></i> Raising Capital</a>
                            <a className="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" 
                                role="tab" aria-controls="v-pills-settings" 
                                aria-selected="false"><i className="fas fa-lock"></i> Product Ready</a>
                        </div>
                    </div>  
                </div>
                <div className="col-lg-9">
                    <div className="tab-content" id="v-pills-tabContent">
                        <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="appie-features-thumb text-center wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                        <img src={FeatureImg1} alt=""/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="appie-features-content wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                        <span>Our Main Focus</span>
                                        {/* <h3 className="title">Our Focus</h3> */}
                                        <p>Every startup requires a certain level of support to grow and build a solid team. 
                                            We devote time and resources to transforming your 
                                            <strong>Ideas</strong> Phase into a finished product for the market. Together with your team, our in-house developers 
                                            will work on projects that bring value to operations. 
                                            We relieve you of part of the burden so you can concentrate on carrying out your central vision.</p>
                                        {/* <a className="main-btn" href="#">Learn More</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="appie-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                        <img src={FeatureImg2} alt=""/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="appie-features-content animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                        <span>Market Readiness</span>
                                        {/* <h3 className="title">Let the <br/> Conversation flow</h3> */}
                                        <p>We assist startups in creating compelling strategic narratives, defining and evaluating product-market 
                                            fit, and developing effective go-to-market strategies that will best position and launch their products. 
                                            By opportunity and product-led growth models and tactics, 
                                            we help businesses farther down their path with fine-tuning consumer engagement and retention.</p>
                                        {/* <a className="main-btn" href="#">Learn More</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="appie-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                        <img src={FeatureImg3} alt=""/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="appie-features-content animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                        <span>Raising Funds</span>
                                        {/* <h3 className="title">Let the <br/> Conversation flow</h3> */}
                                        <p>One of the Major challenges young startups face is Raising capital to build these amazing dreams. 
                                            We are focused on linking viable startups with VCs in interested domains. 
                                            We provide the connections and intro between VCs and new market Ventures across Africa</p>
                                        {/* <a className="main-btn" href="#">Learn More</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="appie-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                        <img src={FeatureImg4} alt=""/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="appie-features-content animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                        <span>Product Ready</span>
                                        {/* <h3 className="title">Let the <br/> Conversation flow</h3> */}
                                        <p>Having a great <strong>Idea</strong> doesn't guarantee success but it is the first step in realising a great company.
                                            Here at Startups4Africa, we assist in product building for market fit and customer acquisition.
                                        </p>
                                        {/* <a className="main-btn" href="#">Learn More</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="features-shape-1">
            <img src={ShapeImg6} alt=""/>
        </div>
        <div className="features-shape-2">
            <img src={ShapeImg7} alt=""/>
        </div>
        <div className="features-shape-3">
            <img src={ShapeImg8} alt=""/>
        </div>
    </section>
  )
}

export default Features