import {createRoot} from 'react-dom/client'
import App from './App'
import './App.css'
import './index.css'
import './default.css'
import './animate.min.css'




<script src="assets/js/main.js"></script>
const container = document.getElementById('root')
const root = createRoot(container)

root.render(<App/>)