import { useState, useEffect } from "react";
import axios from "axios";
import { apiURL } from "../../../utils/Common";
import { config } from "../overview/Ov";


const VcStage = ({stage}) => {
    const [stageData, setStageData] = useState({})

    // ======get stage array==========
    useEffect(() => {

         axios.post(`${apiURL}startup_development_stage/get/`, {'uuid':stage?.development_stage_uuid}, config)
                .then((response) => {
                    setStageData(response.data)
                    // console.log(response.data);
                }).catch((error) => {
                    console.log(error.response.message)
                })
                
                
    }, [stage.development_stage_uuid]);
    // console.log(stage.development_stage_uuid);
  

    return(
        <>
        <p className="startup-category" >{stageData.name} </p>
        </>
    )
}
export {VcStage}