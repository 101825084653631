import React, {useState} from 'react'
import DashboardSidebar from '../../../components/dashboard/DashboardSidebar'
import DashbordNavbar from '../../../components/dashboard/DashbordNavbar'
import StartupsOverview from './StartupsOverview'
import './startupDetails.css'
import AuthService from "../../../utils/Common"

const StartupCard = () => {
  const currentUser = AuthService.getCurrentUser()

  const [userInfo, setUserInfo] = useState(currentUser);
  return (
    <div className='admin-dashboard'>
        <DashbordNavbar/>
        <DashboardSidebar/>
        <div className="dashboard-main">
        <StartupsOverview userInfo={userInfo} setUserInfo={setUserInfo}/>

      </div>
    </div>
  )
}

export default StartupCard