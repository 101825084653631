import React from 'react'
import DashboardSidebar from '../../../components/dashboard/DashboardSidebar'
import DashbordNavbar from '../../../components/dashboard/DashbordNavbar'
import StartupDetailInfo from './StartupDetailInfo'
import './startupDetails.css'


const StartupDetails = () => {
  return (
    <div className='admin-dashboard'>
        <DashbordNavbar/>
        <DashboardSidebar/>
        <div className="dashboard-main">
       
          <StartupDetailInfo/>
       

      </div>
    </div>
  )
}

export default StartupDetails