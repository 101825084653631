import React from 'react'
import './anime.css'


import heroThumb2 from '../../assets/vid/st2.gif'

const Services = () => {
  return (
    
    
    <section className="appie-service-area pt-90 pb-100 animated fadeInRight" id="service" data-wow-duration="2000ms" data-wow-delay="600ms">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-lg-8 col-offset-1">
                <div className="appie-hero-content">
                    <div className="thumb wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        {/* <img src={heroThumb1} alt=""/> */}
                        <div className="">
                        <h3>Is the Startup4Africa a Startup Accelerator?</h3> <br />
                            <p>Like a Startup Accelerator, startups4Africa Program helps startups scale and grow. However, 
                            unlike typical Startup Accelerators, we do not invest funds in your company. Instead, we rely on our partners and investors in our program to invest and provide business advise. 
                            </p>
                            <br />
                            <strong><b><p>Our mission is to provide the best possible 
                            platform(Marketplace), to accelerate your growth. We build and advise startups, therefore, in their infrastructure development to Market-fit.</p></b></strong> <br />
                            <p>Are you an enabler - such as an accelerator, incubator, Angel Investor, venture capital fund, or specialised expert - and want to be part of our program?</p>
                            <p>Contact Us through joinus@Startup4Africa.com. We believe together we can build and be part of an innovative ecosystem that develops future businesses and empowers young African founders networks.</p>
                        </div>
                    </div>
                    <div className="thumb-2 wow animated fadeInRight rushCustom" data-wow-duration="2000ms" data-wow-delay="600ms">
                        {/* <img src={heroThumb2} alt=""/> */}
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

  )
}

export default Services