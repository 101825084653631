import React, { useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

// import './loader.js'
import About from './pages/About'
import Contact from './pages/Contact'
import Error from './pages/Error'
import Home from './pages/Home'
import Login from './pages/Login'
import ProtectedRoute from './pages/ProtectedRoute'
import SignUp from './pages/SignUp'
import StartupProfile from './pages/StartupProfile'
import StartUps from './pages/StartUps'
import Dashboard from './pages/Dashboard'
import StartupDetails from './pages/dashboard/overview/StartupDetails'
import Settings from './pages/dashboard/settings/Settings'
import StartupCard from './pages/dashboard/startupsview/StartupCard'
import Vc from './pages/dashboard/vc/Vc'
import VcProfile from './pages/dashboard/vc/VcProfile'
import EditInvestor from './pages/dashboard/vc/EditInvestor'
import Categories from './pages/admin/setup/categories/Categories'
import CategoryEdit from './pages/admin/setup/categories/CategoryEdit'
import StartupSettings from './pages/admin/setup/startups/StartupSettings'
import EditMyStartup from './pages/admin/setup/startups/EditMyStartup'
import DevelopmentStage from './pages/admin/setup/stages/DevelopmentStage'
import DevelopmentStageEdit from './pages/admin/setup/stages/DevelopmentStageEdit'
import RaisePhase from './pages/admin/setup/raise/RaisePhase'
import RaisePhaseEdit from './pages/admin/setup/raise/RaisePhaseEdit'
import Users from './pages/admin/setup/users/Users'
import UserEdit from './pages/admin/setup/users/UserEdit'
import { useEffect } from 'react'
// import Editinvestor from './pages/dashboard/vc/EditInvestor'

const App = () => {

    
// const [isLoggedIn, setIsLoggedIn] = useState()
// console.log(`the login state is ${isLoggedIn}`);

// useEffect(() =>{
//    function checkLogin() {
//     const loginvalue = localStorage.getItem("login")
//     if (loginvalue) {
//         // Restore the contents of the text field
//         console.log(localStorage.getItem("login"));
//         setIsLoggedIn(true)
//       } else{
//         setIsLoggedIn(false)
//       }
      
//    }

//    checkLogin()
// },[])

// console.log(`isLogged In : ${isLoggedIn}`);
    
        

  return (
    <BrowserRouter>
            <Routes>
                
                
                        
                        <Route path='startups/:id' element={
                            <ProtectedRoute >
                                 <StartupProfile/>
                             </ProtectedRoute>
                        }/>
                        <Route path='dashboard/settings' element={<ProtectedRoute >
                                <Settings/>
                            </ProtectedRoute>}/>
                        <Route path='dashboard/startups/create' element={<ProtectedRoute >
                                <StartupSettings/>
                            </ProtectedRoute>}/>
                       
                        <Route path='dashboard/startup/detail/:id' element={<ProtectedRoute >
                                <StartupDetails/>
                            </ProtectedRoute>}/>
                        <Route path='dashboard/user/mystartup/edit/:id' element={<ProtectedRoute >
                                <EditMyStartup/>
                            </ProtectedRoute>}/>
                        <Route path='dashboard/vc/connected_startups/list' element={<ProtectedRoute >
                                <Vc/>
                            </ProtectedRoute>}/>
                        <Route path='dashboard/vc/investment/profile' element={<ProtectedRoute >
                                <VcProfile/>
                            </ProtectedRoute>}/>
                            <Route path='dashboard/vc/investor/edit/profile/:id' element={<ProtectedRoute >
                                <EditInvestor/>
                            </ProtectedRoute>}/>
                        <Route path='dashboard/admin/setup/categories' element={<ProtectedRoute >
                                <Categories/>
                            </ProtectedRoute>}/>
                        <Route path='dashboard/admin/setup/categories/edit/:id' element={<ProtectedRoute >
                                <CategoryEdit/>
                            </ProtectedRoute>}/>
                        <Route path='dashboard/admin/setup/dev_stages' element={<ProtectedRoute >
                                <DevelopmentStage/>
                            </ProtectedRoute>}/>
                        <Route path='dashboard/admin/setup/dev_stages/edit/:id' element={<ProtectedRoute >
                                <DevelopmentStageEdit/>
                            </ProtectedRoute>}/>
                        <Route path='dashboard/admin/setup/raise_phase' element={<ProtectedRoute >
                                <RaisePhase/>
                            </ProtectedRoute>}/>
                        <Route path='dashboard/admin/setup/raise_phase/edit/:id' element={<ProtectedRoute >
                                <RaisePhaseEdit/>
                            </ProtectedRoute>}/>
                        <Route path='dashboard/users' element={<ProtectedRoute >
                                <Users/>
                            </ProtectedRoute>}/>
                        <Route path='dashboard/setup/users/edit/:id' element={<ProtectedRoute >
                                <UserEdit/>
                            </ProtectedRoute>}/>
                        <Route path='/dashboard/overview/allstartups/' element={<ProtectedRoute >
                                <StartupCard/>
                            </ProtectedRoute>}/>


                        <Route path='dashboard' element={
                            <ProtectedRoute >
                                <Dashboard/>
                            </ProtectedRoute>
                        }/>
                        

                        <Route exact path='/' element={<Home />}/>
                        <Route path='contact' element={<Contact/>}/>
                        <Route path='startups' element={<StartUps/>}/>
                        <Route path='login' element={<Login/>}/>
                        <Route path='register' element={<SignUp/>}/>
                        <Route path='about' element={<About/>}/>
                       
                   
                
                 <Route path='*' element={<Error/>}/>
                
                



                
                


            </Routes>
        </BrowserRouter>
  )
}

export default App