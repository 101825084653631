import React from 'react'
import './project.css'
import projectImg from '../../assets/images/home/bottom.jpg'

const Projects = () => {
  return (
    <section className="appie-project-area pb-100">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="appie-project-box wow animated slideInUp" data-wow-duration="1000ms" data-wow-delay="0ms">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="appie-project-content">
                                    <h3 className="title">Start with Us.</h3>
                                    <p>We will email you only about this product.</p>
                                    <form action="#">
                                        <div className="input-box mt-30">
                                            <input type="text" placeholder="Your email"/>
                                            <button>Subscribe</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="appie-project-thumb">
                            <img src={projectImg} alt="" height="300" width="350"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Projects