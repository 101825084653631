import React, {useState, useEffect} from 'react'
import './vc.css'
import VcProfileDetails from './VcProfileDetails'
import DashboardSidebar from '../../../components/dashboard/DashboardSidebar'
import DashbordNavbar from '../../../components/dashboard/DashbordNavbar'
import AuthService, {apiURL, imgUrl} from "../../../utils/Common"
import axios from 'axios'
import { config } from '../overview/Ov'


const VcProfile = () => {
    const currentUser = AuthService.getCurrentUser()
    const [userInfo, setUserInfo] = useState(currentUser);
    const [investor, setInvestor] = useState({})

    const[loading, setLoading] = useState('')
     // get user ID and access token
    // console.log(currentUser.investor_uuid);
     
       // Get investor
      
//    console.log(userInfo);
       useEffect(() => {  
            const getInvestor = async () => {
                return axios.post(`${apiURL}vc/get/`, {'investor_uuid':currentUser.investor_uuid}, config)
                    .then(response => {
                        if (response.status === 200) {
                            setInvestor(response.data)
                            console.log(investor);
                        }                       
                        return
                    }).catch(error => {
                        console.log('error here');
                    })
            }                      
           getInvestor()
         }, [currentUser.investor_uuid])


  return (
      <div className='admin-dashboard'>
        <DashbordNavbar/>
        <DashboardSidebar/>
        <div className="dashboard-main">
          <VcProfileDetails userInfo={userInfo} investor={investor} />

        </div>
      </div>
  )
}

export default VcProfile