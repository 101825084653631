import React, {useState, useEffect} from 'react'
import startupLogo from '../../../../assets/images/blog/p3.jpg'
import {BsArrowRightShort} from 'react-icons/bs'
import AuthService, {apiURL} from '../../../../utils/Common'
import axios from "axios"
import {useNavigate, useLocation, useParams} from "react-router-dom";
import { config } from '../../../dashboard/overview/Ov'

const DevelopmentStageEditDetail = ({index}, props) => {
    const {id} = useParams(index);
    const stageId = {id}.id

    

    const navigate = useNavigate()

    const currentUser = AuthService.getCurrentUser()
    const[errorMessage, setErrorMessage] = useState("")
    const[successMessage, setSuccessMessage] = useState("")
    const [loading, setLoading] = useState(false);

    const[stageInfo, setStageInfo] = useState({
        name: '',
        slug: '',
        uuid: ''
    });

    // console.log(stageInfo);
    // console.log(stageId);



// END of getting Category by UUID
// +===================================================================
 
useEffect(() => {
        const getStage = async () => {
            return axios.post(`${apiURL}startup_development_stage/get/`, {'uuid':stageId}, config)
                .then((response) => {
                    // const { name,slug, uuid } = response.data;
                    // setStageInfo({ name, slug, uuid });
                    setStageInfo(response.data)
                    // console.log(response.data);
                }).catch((error) => {
                    setErrorMessage(error.response.message)
                })
        };
         getStage()
    }, [stageId]);

// Update Category=======================================

    const handleChange = e => {
        const {name, value} = e.target
        setStageInfo({
          ...stageInfo, 
          [name]:value
        })
      }

    const updateUrl = `${apiURL}startup_development_stage/update/`
    const handleUpdateDevPhase = (e) => {
        e.preventDefault();
        setLoading(true)
        return axios.post(updateUrl, {uuid:stageInfo.uuid, name: stageInfo.name}, config)
            .then((response) => {
                //console.log(response.data);
                if (response.status === 200) {
                    alert("Dev Phase successfully updated");
                    //props.history.push("/categories");
                    navigate("/dashboard/admin/setup/dev_stages/")
                    setSuccessMessage("Dev Phase updated successfully")
                  }else Promise.reject();
            }).catch((error) => {
                console.log(error.response.data);
            })
        
    }


  
  return (
  <section>
    <div className="overview-title">
        <h1>Edit Development Phase(Stage)</h1>
    </div>
    <div className="row">
       
        <div className="col-md-8 offset-1">
                <div className="startup-d-container">
                <div className="startup-d-header">
                    <div className="startup-header-info">
                        <p className="startup-category">Startup Phase</p>
                        <h3 className="startup-name">Edit Phase</h3>
                    </div>

                </div>
                <div className="startup-d-desc">
                <div className="contact-form">
                            <span className="text-success">{successMessage}</span>
                            <span className="text-danger">{errorMessage}</span>
                            <form action="#" method="post" onSubmit={handleUpdateDevPhase} className="row">
                                <div className="col-md-12">
                                    <label htmlFor="category">Startup Dev Phase name</label>
                                    <input type="text" name="name"
                                    value={stageInfo.name}
                                    onChange={handleChange}
                                    placeholder="Startup Phase Name"/>
                                </div>
                                
                                <div className="col-md-12 text-right">
                                   
                                    <input type="submit" name="submit" value="Update Dev Phase"/>
                                </div>
                            </form>
                        </div>
          

                </div>
                <div className="startup-d-contact">
                    {/* <a href="/contact">Contact Startup <BsArrowRightShort/></a> */}
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default DevelopmentStageEditDetail