import React, {useState, useEffect} from 'react'
import startupLogo from '../../../../../assets/images/blog/p3.jpg'
import {BsArrowRightShort} from 'react-icons/bs'
import AuthService, {apiURL, imgUrl} from '../../../../../utils/Common'
import axios from "axios"
import {useNavigate, useParams} from "react-router-dom";
import { config } from '../../../../dashboard/overview/Ov'
import './create_startup.css'

const EditStartUp = ({index}, props) => {
    const {id} = useParams(index);
    
  const currentUser = AuthService.getCurrentUser()
  const [userInfo, setUserInfo] = useState(currentUser);
  const[isStartup, setIsStartup] = useState({})
  const [catloading, setCatTLoading] = useState(false);
  const [devloading, setDevLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const[published, setPublished] = useState('')
  const userRole = userInfo.role
  
  const[errorMessage, setErrorMessage] = useState("")
  const[successMessage, setSuccessMessage] = useState("")
  const navigate = useNavigate()
  const [startupLogo, setStartupLogo] = React.useState(null);
  const [startupPitchDeckFile, setStartupPitchDeckFile] = React.useState(null);


  // ================GET STARTUP  BY ID=========================================
  const getStartupUrl = apiURL + "startups/get/"
  useEffect(() =>{
    const startupId = {id}.id
      const getStartupByID = () => {
          return axios.post(getStartupUrl, {'startup_uuid':startupId}, config)
          .then(response => {
              if (response.status === 200) {
                  setIsStartup(response.data)
                //   console.log(response.data);
                //   getCategoryByID(response.data.category_uuid)
                //   getDevStageById(response.data.development_stage_uuid)
              }else Promise.reject()
              return 
          })
          };
          getStartupByID()

  },[])


  //====================GET ALL CATEGORIES=================
  const [catData, setCatData] = useState([])
  useEffect(() => {
      const getUrl = apiURL + "startup_categories/list/"
      setCatTLoading(true)
      const getCategory = () => {
          return axios.get(getUrl, config)
           .then(response => {
              setCatData(response.data.data)
              setCatTLoading(false)
            return 
          })
        };
        getCategory()
    },[loading])

        //   console.log(startupCategory);
  const listCategory = catData.map((category, i) =>
      <option key={category.uuid} value={category.uuid}>{category.name}</option>   

  )

  //====================GET ALL DEV Stages(Phases)=================
  const [devStage, setDevStage] = useState([])
      useEffect(() => {
          const getUrl = apiURL + "startup_development_stage/list/"
          setDevLoading(true)
          const getDevStages = () => {
              return axios.get(getUrl, config)
               .then(response => {
                  if (response.status === 200) {
                      setDevStage(response.data.data)
                      setDevLoading(false)
                  }else Promise.reject()                                       
                return 
              })
            };
            getDevStages()
        },[loading])
        const listDevStage = devStage.map((stage, i) =>
          <option key={stage.uuid} value={stage.uuid}>{stage.name}</option> 
        )

    //====================GET ALL Raising Funds (Phases)=================

    const [raisePhase, setRaisePhase] = useState([])
    useEffect(() => {
        const getUrl = apiURL + "startup_raising_phase/list/"
        setDevLoading(true)
        const getRaisePhases = () => {
            return axios.get(getUrl, config)
             .then(response => {
                if (response.status === 200) {
                    setRaisePhase(response.data.data)
                    setDevLoading(false)
                }else Promise.reject()                                       
              return 
            })
          };
          getRaisePhases()
      },[loading])

//===================================== 
  //   console.log(startupCategory);
  const listRaisePhase = raisePhase.map((raise, i) =>
      <option key={raise.uuid} value={raise.uuid}>{raise.name}</option>   
  )

  // ===========Create startup Process ===========================
  
  const updateUrl = `${apiURL}startups/update/`

//   const [newStartup, setNewStartup] = useState({
//       // name: ""
//     })
//   const[startupData, setStartupData] = useState('')
  const handleFormChange = e => {
      const {name, value} = e.target
      setIsStartup({
        ...isStartup, startup_logo:startupLogo, pitch_deck_file:startupPitchDeckFile,
        [name]:value
      })
    }
// ===================================== Startup Logo
        // const params = useParams();
  // console.log(userInfo);
  const handleFileSelect = (event) => {
      setStartupLogo(event.target.files[0])
    }
  // ===============================================IMAGE UPLOAD================================

  const  uploadImage = () => {
      const data = new FormData() 
      data.append('startup_logo', startupLogo)
      // console.warn(userImage);
      // console.log(data);
      axios.post(updateUrl, data, config)
      .then(response => { // then print response status
        setIsStartup(response.data)
          // console.warn(res);
      })
  }

  // =====================================================================
  // ===================================== pitch deck file
        // const params = useParams();
  // console.log(userInfo);
  const handlePitchDeckFileSelect = (event) => {
      setStartupPitchDeckFile(event.target.files[0])
    }
  // ===============================================IMAGE UPLOAD================================

  const  uploadPitchDeck = () => {
      const data = new FormData() 
      data.append('pitch_deck_file', startupPitchDeckFile)
      // console.warn(userImage);
      // console.log(data);
      axios.post(updateUrl, data, config)
      .then(response => { // then print response status
        setIsStartup(response.data)
          // console.warn(res);
      })
  }

  // =====================================================================
//   console.log(isStartup);
  const handleUpdateStartup = (e) => {
      e.preventDefault()
      if (document.getElementById('hidden_field_choice_selector_parent_element'.value===true) ) {
          return alert("Startup Contact Person's Information is required. Please Click on Next Tab to Complete")
      }else {
          console.log(isStartup);
          setLoading(true)
          uploadImage()
          uploadPitchDeck()
          axios.post(updateUrl, isStartup, config)
              .then((response) => {
                  setLoading(false)
                  if (response.status === 200) {
                      
                    //   alert("Startup Updated successfully");
                      setIsStartup(response.data)
                    //   navigate("/dashboard/startups/create/")
                      window.location.reload()
                      setSuccessMessage("Startup Created successfully")
                      setIsStartup({isStartup: ''})
                  }else Promise.reject()
              }).catch((error) => {
                  setErrorMessage(error.response.data)
              })
      }
  }

//   ===============PUBLISH STARTUP
const updatePubUrl = `${apiURL}startups/publish/`
const publishStartup = (e) => {
    e.preventDefault()
    axios.post(updatePubUrl, {"is_published":"true", "startup_uuid":isStartup.startup_uuid}, config)
        .then((response) => {
            if(response.status == 200) {
                setPublished(true)
                alert('Your startup is now live')
                // window.location.reload()
            }
        })
}

// =========================unpublish startups
const unupdatePubUrl = `${apiURL}startups/unpublish/`
const UnpublishStartup = (e) => {
    e.preventDefault()
    axios.post(unupdatePubUrl, {"is_published":false, "startup_uuid":isStartup.startup_uuid}, config)
        .then((response) => {
            if(response.status == 200) {
                setPublished(false)
                // console.log(response.data);
                alert('Your startup has been unpublished & Investors will no longer see it again.')
                // window.location.reload()
            }
        })
}


  return (
    <section>
      <form action="#" method="post" onSubmit={handleUpdateStartup}  encType="multipart/form-data" >
      <div className="container-xl">
        <h1 className="app-page-title">Edit {isStartup.startup_name}</h1>
        <div className="row g-4 settings-section">
	                <div className="col-12 col-md-4">
		                <h3 className="section-title">Save Startup Information</h3>
		                {/* <div className="section-intro">Settings section intro goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. <a href="help.html">Learn more</a></div> */}
	                </div>
	                <div className="col-12 col-md-8">
		                <div className="app-card app-card-settings shadow-sm p-4">
						    
						    <div className="app-card-body">
                                <div className="d-flex wizard justify-content-between mt-3">
                                    <div className="first">
                                        {/* <a href="javascript:void(0);" className="btn btn-primary">
                                            First
                                        </a> */}
                                        {isStartup.is_published === false ?
                                        <>
                                        <button type='submit' onClick={publishStartup} className="btn btn-primary mt-3 mt-md-0">
                                            {loading ? <>Publishing Draft..</> : <>Publish Startup</>}
                                        </button>   
                                        </>
                                        :
                                        <>
                                        <button type='submit' onClick={UnpublishStartup} className="btn btn-danger mt-3 mt-md-0">
                                            {loading ? <>Publishing Draft..</> : <>UNPublish Startup</>}
                                        </button>
                                        </>
                                        }
                                        

                                    </div>
                                    
                                    <div className="last">
                                        {/* <a href="javascript:void(0);"  className="btn btn-primary mt-3 mt-md-0">
                                            Submit
                                        </a> */}
                                        <button type="submit" className="btn btn-primary mt-3 mt-md-0">
                                            {loading ? <>Processing...</> : <>Save Draft</>}
                                        </button>
                                    </div>
                                </div>
								    
						    </div>
						    
						</div>
	                </div>
                </div>
                <span className="text-success">{successMessage}</span>
                <span className="text-danger">{errorMessage}</span>
            <hr className="mb-4"/>
                  <div className="row g-4 settings-section">
                    <div className="col-12 col-md-4">
                      <h3 className="section-title">Startup Details</h3>
                      <div className="section-intro">Welcome! <br /> We love to know more about your startup and your Journey</div>
                    </div>
                    <div className="col-12 col-md-8">
                      <div className="app-card app-card-settings shadow-sm p-4">
                  
                  <div className="app-card-body">
                 
                    <div className="row">
                      <div className="col-md-12">
                          <div className="mb-3">
                          <label htmlFor="setting-input-1" className="form-label required">Startup Name<span className="ms-2" data-container="body" data-bs-toggle="popover" data-trigger="hover" data-placement="top" data-content="This is a Bootstrap popover example. You can use popover to provide extra info.">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-info-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"/>
                          <circle cx="8" cy="4.5" r="1"/>
                          </svg></span></label>
                              {/* <label className="form-label " 
                                  htmlFor="Startup Name">Startup Name</label> */}
                              <input
                                      type="text"
                                      className="form-control"
                                      id="startup_name"
                                      onChange={handleFormChange}
                                      value={isStartup.startup_name}
                                      name="startup_name"
                                      placeholder="Enter your startup name"
                                      required
                              />
                          </div>
                      </div>
                      <div className="col-md-12">
                          <div className="mb-3">
                              <label className="form-label required" htmlFor="Startup Address">Startup Address</label>
                              <textarea 
                              onChange={handleFormChange}
                              value={isStartup.enter_full_address}
                              className="form-control"
                              name="enter_full_address" 
                              id="" cols="30" rows="2"></textarea>
                          </div>
                      </div>
                      <div className="col-md-12">
                          <div className="mb-3">
                              <label className="form-label required" htmlFor="country">Country</label>
                              <input
                                      type="text"
                                      className="form-control"
                                      id="country"
                                      onChange={handleFormChange}
                                      value={isStartup.country}
                                      name="country"
                                      placeholder="Established country"
                                      required
                              />
                          </div>
                      </div>
                      <div className="col-md-12">
                          <div className="mb-3">
                              <label className="form-label required" htmlFor="region">Region</label>
                              <input
                                      type="text"
                                      className="form-control"
                                      id="region"
                                      onChange={handleFormChange}
                                      value={isStartup.region}
                                      name="region"
                                      placeholder="Region"
                                      required
                              />
                          </div>
                      </div>
                      <div className="col-md-12">
                          <div className="mb-3">
                              <label className="form-label required" htmlFor="City">City</label>
                              <input
                                      type="text"
                                      className="form-control"
                                      id="city"
                                      onChange={handleFormChange}
                                      value={isStartup.city}
                                      name="city"
                                      placeholder="City"
                                      required
                              />
                          </div>
                      </div>
                      <div className="col-md-12">
                          <div className="mb-3">
                              <label className="form-label required" htmlFor="Country of Registration">Country of Registration</label>
                              <input
                                      type="text"
                                      className="form-control"
                                      id="country_of_incorporation"
                                      onChange={handleFormChange}
                                      value={isStartup.country_of_incorporation}
                                      name="country_of_incorporation"
                                      placeholder="Registration country"
                                      required
                              />
                          </div>
                      </div>
                      <div className="col-md-12">
                          <div className="mb-3">
                              <label className="form-label required" htmlFor="Established">Registered Date</label>
                              <input
                                      type="date"
                                      className="form-control"
                                      onChange={handleFormChange}
                                      value={isStartup.established_in}
                                      id="established_in"
                                      name="established_in"
                                      required
                              />
                          </div>
                      </div>
                      <div className="col-md-12">
                          <div className="mb-3">
                              <label className="form-label required" htmlFor="Startup Category">Startup Sector (Category)</label>
                              <select name="category_uuid" id=""
                                  onChange={handleFormChange}
                                  value={isStartup.category_uuid} 
                                  className="form-control" required>
                                  <option value=""></option>
                                  {listCategory}
                              </select>
                          </div>
                      </div>
                      <div className="col-md-12">
                          <div className="mb-3">
                              <label className="form-label required" htmlFor="Development Stage">Development Stage</label>
                              <select name="development_stage_uuid" id="" 
                                  className="form-control" 
                                  onChange={handleFormChange}
                                  value={isStartup.development_stage_uuid}
                                  required>
                                  <option value=""></option>
                                  {listDevStage}
                              </select>
                          </div>
                      </div>
                  </div>
                    {/* </form> */}
                  </div>
                  
              </div>
              </div>
          </div>


          <hr className="mb-4"/>
                  <div className="row g-4 settings-section">
                    <div className="col-12 col-md-4">
                      <h3 className="section-title">Startup Contact Information</h3>
                      <div className="section-intro">Tell us about your Startup and It's Contact Person</div>
                    </div>
                    <div className="col-12 col-md-8">
                      <div className="app-card app-card-settings shadow-sm p-4">
                  
                  <div className="app-card-body">
                   
                     <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="Website">Startup Website</label>
                                <input
                                        type="link"
                                        className="form-control"
                                        id="startup_website"
                                        onChange={handleFormChange}
                                        value={isStartup.startup_website}
                                        name="startup_website"
                                        placeholder="https://example.com"
                                        
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="Twitter profile">Twitter Profie</label>
                                <input
                                        type="link"
                                        className="form-control"
                                        id="twitter_profile"
                                        name="twitter_profile"
                                        placeholder="https://twitter.com/startup"
                                        onChange={handleFormChange}
                                        value={isStartup.twitter_profile}
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="LinkedIn profile">LinkedIn Profie</label>
                                <input
                                        type="link"
                                        className="form-control"
                                        id="linkedin_profile"
                                        name="linkedin_profile"
                                        placeholder="https://linkedin.com/startup"
                                        onChange={handleFormChange}
                                        value={isStartup.linkedin_profile}
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="startup_logo">Upload Startup Logo</label>
                                <input
                                        type="file"
                                        className="form-control"
                                        accept="image/*"
                                        id="startup_logo"
                                        name="startupLogo"
                                        placeholder="upload ur logo here"
                                        onChange={handleFileSelect}
                                        // value={newStartup.startup_logo}
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="Contact Name">Contact Person</label>
                                <input
                                        type="text"
                                        className="form-control"
                                        id="contact_name"
                                        onChange={handleFormChange}
                                        value={isStartup.contact_name}
                                        name="contact_name"
                                        placeholder="Contact Name"
                                        required
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="Contact Address">Contact Address</label>
                                <input
                                        type="text"
                                        className="form-control"
                                        id="contact_destination"
                                        onChange={handleFormChange}
                                        value={isStartup.contact_destination}
                                        name="contact_destination"
                                        placeholder="Contact Address"
                                        required
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="Contact Phone">Contact Phone Number</label>
                                <input
                                        type="text"
                                        className="form-control"
                                        id="contact_phone_number"
                                        onChange={handleFormChange}
                                        value={isStartup.contact_phone_number}
                                        name="contact_phone_number"
                                        placeholder="Contact Phone"
                                        required
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="Contact Email">Contact Email</label>
                                <input
                                        type="email"
                                        className="form-control"
                                        id="contact_email"
                                        onChange={handleFormChange}
                                        value={isStartup.contact_email}
                                        name="contact_email"
                                        placeholder="Contact Phone"
                                        required
                                />
                            </div>
                        </div>
                    </div>
                    {/* </form> */}
                  </div>
                  
              </div>
              </div>
          </div>



          <hr className="mb-4"/>
                  <div className="row g-4 settings-section">
                    <div className="col-12 col-md-4">
                      <h3 className="section-title">Startup Analysis</h3>
                      <div className="section-intro">Tell us about your Startup</div>
                    </div>
                    <div className="col-12 col-md-8">
                      <div className="app-card app-card-settings shadow-sm p-4">
                  
                  <div className="app-card-body">
                
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="Founder's Name">Founder's Name</label>
                                <input
                                        type="text"
                                        className="form-control"
                                        id="founders_name"
                                        onChange={handleFormChange}
                                        value={isStartup.founders_name}
                                        name="founders_name"
                                        placeholder="enter your Founder's name"
                                        required
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" for="schoolLocation"
                                >Founder & Team Background</label>
                                <textarea name="founders_background" id="" 
                                    onChange={handleFormChange}
                                    value={isStartup.founders_background}
                                    cols="30" rows="3" 
                                    className="form-control required" required
                                    placeholder="tell us about the founder"></textarea>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="problem solving">Problem You're Solving</label>
                                <textarea name="problem_your_solving" 
                                    className="form-control" id="" 
                                        onChange={handleFormChange}
                                        value={isStartup.problem_your_solving}
                                    required cols="30" rows="5"></textarea>
                        
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="solution">
                                    What is your solution to the problem</label>
                                <textarea name="solution" 
                                    className="form-control" 
                                    onChange={handleFormChange}
                                    value={isStartup.solution}
                                    id="" required cols="30" rows="5"></textarea>                                        
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="usp_market">
                                    What is the startup's unique selling proposition (USP) to the market?</label>
                                <textarea name="usp_market" 
                                    className="form-control" 
                                    onChange={handleFormChange}
                                    value={isStartup.usp_market}
                                    required id="" cols="30" rows="5"></textarea>                                        
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="business_model">
                                    How does your business make money (What's your business model)?</label>
                                <textarea name="business_model" 
                                    className="form-control" 
                                    onChange={handleFormChange}
                                    value={isStartup.business_model}
                                    id="" required cols="30" rows="5"></textarea>                                        
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="raising_phase_uuid">Raise Phase(Select)</label>
                                <select name="raising_phase_uuid" id="" 
                                    className="form-control" 
                                    onChange={handleFormChange}
                                    value={isStartup.raising_phase_uuid}
                                    required>
                                    <option value=""></option>
                                    {listRaisePhase}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="fulltime_employees">
                                    How many Full-Time Employees (FTE) does the startup have?</label>
                                <input type="number" 
                                    className="form-control"
                                    onChange={handleFormChange}
                                    value={isStartup.fulltime_employees}
                                    name="fulltime_employees" required
                                />                                        
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="sdg_goals">
                                Which Sustainable Development Goals (SDG) does your company contribute to (i.e. 1 - 17)?</label>
                                <textarea name="sdg_goals" 
                                    id="" 
                                    className="form-control"
                                    onChange={handleFormChange}
                                    value={isStartup.sdg_goals}
                                    required
                                    cols="30" 
                                    rows="5"></textarea>                                                                                      
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="sgd_focus_goal">
                                    Which are the main SDG impact areas your company focuses on?</label>
                                <textarea name="sgd_focus_goal" 
                                    id="sgd_focus_goal" 
                                    className="form-control"
                                    onChange={handleFormChange}
                                    value={isStartup.sgd_focus_goal}
                                    required
                                    cols="30" 
                                    rows="5"></textarea>                                                                                      
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="percentage owned">
                                what percentage is owned by You
                                </label>
                                <input type="number"
                                    onChange={handleFormChange}
                                    value={isStartup.percentage_owned}
                                    name="percentage_owned"
                                    className="form-control"
                                    required />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="equity_not_owned_by_you">
                                How much equity is owned by other main shareholder besides founder(s), 
                                who are they and what percentages do they own?</label>
                                <textarea name="equity_not_owned_by_you" 
                                    id="equity_not_owned_by_you" 
                                    className="form-control"
                                    onChange={handleFormChange}
                                    value={isStartup.equity_not_owned_by_you}
                                    required
                                    cols="30" 
                                    rows="5"></textarea>                                                                                      
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="Funds received">
                                What is the total value of Angel investment received by the Startup?
                                </label>
                                <input
                                        type="number"
                                        className="form-control"
                                        id="received_funds_before"
                                        name="received_funds_before"
                                        onChange={handleFormChange}
                                        value={isStartup.received_funds_before}
                                        // placeholder="Funds Received"
                                        required
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="investments received">
                                When was the investment from the Angel investor(s) received by the Startup?
                                </label>
                                <input
                                        type="text"
                                        className="form-control"
                                        id="investment_from_angel_year"
                                        name="investment_from_angel_year"
                                        onChange={handleFormChange}
                                        value={isStartup.investment_from_angel_year}
                                        // placeholder="Funds Received"
                                        required
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="latest_funding_received">
                                What was the latest funding (MM-YY / Type / Amount ($) / Organisation)
                                received by the Startup (if not the Angel investment)?</label>
                                <textarea name="latest_funding_received" 
                                    id="latest_funding_received" 
                                    className="form-control"
                                    onChange={handleFormChange}
                                    value={isStartup.latest_funding_received}
                                    required
                                    cols="30" 
                                    rows="5"></textarea>                                                                                      
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="application_of_funds">
                                How is the angel investment being used by the startup (application of funds)?</label>
                                <textarea name="application_of_funds" 
                                    id="application_of_funds" 
                                    className="form-control"
                                    onChange={handleFormChange}
                                    value={isStartup.application_of_funds}
                                    required
                                    cols="30" 
                                    rows="5"></textarea>                                                                                      
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="startup_monthly_revenue">
                                What is the startups monthly revenue for the last 12 months?
                                </label>
                                <input
                                        type="number"
                                        className="form-control"
                                        id="startup_monthly_revenue"
                                        // placeholder="Funds Received"
                                        name="startup_monthly_revenue"
                                        onChange={handleFormChange}
                                        value={isStartup.startup_monthly_revenue}
                                        required
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="cash_burnt_rate">
                                How much is the startup spending each month (i.e. Cash Burn Rate)
                                </label>
                                <input
                                        type="number"
                                        className="form-control"
                                        id="cash_burnt_rate"
                                        // placeholder="Funds Received"\
                                        name="cash_burnt_rate"
                                        onChange={handleFormChange}
                                        value={isStartup.cash_burnt_rate}
                                        required
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="cash_available">
                                How much cash does the startup have in the bank?
                                </label>
                                <input
                                        type="number"
                                        className="form-control"
                                        id="cash_available"
                                        // placeholder="Funds Received"
                                        name="cash_available"
                                        onChange={handleFormChange}
                                        value={isStartup.cash_available}
                                        required
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="paying_customers">
                                How many paying customers does the startup have?
                                </label>
                                <input
                                        type="number"
                                        className="form-control"
                                        id="paying_customers"
                                        // placeholder="Funds Received"
                                        name="paying_customers"
                                        onChange={handleFormChange}
                                        value={isStartup.paying_customers}
                                        required
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="valuation_cap">
                                What was the ($) valuation (or Cap if SAFE or Convertible) 
                                for the startup for the Angel investment?
                                </label>
                                <input
                                        type="number"
                                        className="form-control"
                                        id="valuation_cap"
                                        name="valuation_cap"
                                        onChange={handleFormChange}
                                        value={isStartup.valuation_cap}
                                        // placeholder="Funds Received"
                                        required
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="latest_valuation">
                                If different, what is the startup's latest valuation ($)?
                                </label>
                                <input
                                        type="number"
                                        className="form-control"
                                        id="latest_valuation"
                                        name="latest_valuation"
                                        onChange={handleFormChange}
                                        value={isStartup.latest_valuation}
                                        // placeholder="Funds Received"
                                        required
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="pitch_deck">
                                Pitch Deck Link
                                </label>
                                <input
                                        type="text"
                                        className="form-control"
                                        id="pitch_deck"
                                        name="pitch_deck"
                                        onChange={handleFormChange}
                                        value={isStartup.pitch_deck}
                                        // placeholder="Funds Received"
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="pitch_deck_file">
                                Or Upload Pitch Deck
                                </label>
                                <input
                                        type="file"
                                        className="form-control"
                                        accept='.doc,.docx,application/pdf'
                                        id="pitch_deck_file"
                                        name="pitch_deck_file"
                                        onChange={handlePitchDeckFileSelect}
                                        // value={newStartup.pitch_deck_file}
                                        // placeholder="Funds Received"
                                />
                            </div>
                        </div>
                    </div>
                    {/* </form> */}
                  </div>
                  
              </div>
              </div>
          </div>




          <hr className="my-4"/>
                <div className="row g-4 settings-section">
	                <div className="col-12 col-md-4">
		                <h3 className="section-title">Save Startup Information</h3>
		                {/* <div className="section-intro">Settings section intro goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. <a href="help.html">Learn more</a></div> */}
	                </div>
	                <div className="col-12 col-md-8">
		                <div className="app-card app-card-settings shadow-sm p-4">
						    
						    <div className="app-card-body">
                                <div className="d-flex wizard justify-content-between mt-3">
                                    <div className="first">
                                        {/* <a href="javascript:void(0);" className="btn btn-primary">
                                            First
                                        </a> */}
                                        {isStartup.is_published === false ?
                                        <>
                                        <button type='submit' onClick={publishStartup} className="btn btn-primary mt-3 mt-md-0">
                                            {loading ? <>Publishing Draft..</> : <>Publish Startup</>}
                                        </button>   
                                        </>
                                        :
                                        <>
                                        <button type='submit' onClick={UnpublishStartup} className="btn btn-danger mt-3 mt-md-0">
                                            {loading ? <>Publishing Draft..</> : <>UNPublish Startup</>}
                                        </button>
                                        </>
                                        }
                                        

                                    </div>
                                    
                                    <div className="last">
                                        {/* <a href="javascript:void(0);"  className="btn btn-primary mt-3 mt-md-0">
                                            Submit
                                        </a> */}
                                        <button type="submit" className="btn btn-primary mt-3 mt-md-0">
                                            {loading ? <>Processing...</> : <>Save Draft</>}
                                        </button>
                                    </div>
                                </div>
								    
						    </div>
						    
						</div>
	                </div>
                </div>
      </div>
      </form>
    </section>
  )
}

export default EditStartUp