import React from 'react'
import './users.css'
import UserDetail from './UserDetail'
import DashboardSidebar from '../../../../components/dashboard/DashboardSidebar'
import DashbordNavbar from '../../../../components/dashboard/DashbordNavbar'

const Users = () => {
  return (
    <div className='admin-dashboard'>
        <DashbordNavbar/>
        <DashboardSidebar/>
        <div className="dashboard-main">
        <UserDetail/>

      </div>
    </div>
  )
}

export default Users;
