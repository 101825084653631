import React, { useEffect, useState } from 'react'
import logoImg from '../../assets/images/logo.png'
import logoImgWhite from '../../assets/images/logo-10.png'
import { Link, useNavigate } from "react-router-dom";

import AuthService from '../../utils/Common'

import {FiUser} from 'react-icons/fi'

import './navbar.css'

const Navbar = ({type}) => {

    const[currentUser, setCurrentUser] = useState(undefined)
    const[showDashboard,setShowDashboard] = useState(false)

    useEffect(() =>{
        const user = AuthService.getCurrentUser();
        if(user){
            setCurrentUser(user);
            setShowDashboard(true)
        }

    },[])

    const logOut =() =>{
        AuthService.logout()
        setCurrentUser(undefined)
        setShowDashboard(false)
    }



    let NavbarlinkCSS = ''
    let loginBtnCss = ''
    let logoSrc = logoImg

    if(type ==="others") {
        NavbarlinkCSS = "appie-header-main-menu navWhite"
        loginBtnCss = "login-btn-white"
        logoSrc = logoImgWhite
    } else {
        NavbarlinkCSS = "appie-header-main-menu"
        loginBtnCss ='login-btn'
    }


  return (
    <header className="appie-header-area appie-sticky">
        <div className="container">
            <div className="header-nav-box">
                <div className="row align-items-center">
                    <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
                        <div className="appie-logo-box">
                            <a href="/">
                            <img src={logoSrc} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2">
                        <div className={NavbarlinkCSS}>
                            <ul>
                                <li>
                                <Link  to="/">Home</Link> {" "}
                                </li>

                                <li>
                                <Link to="/about">About</Link>
                                </li>
                                
                                <li> 
                                <Link to="/startups">Portfolio</Link>
                                </li>

                                <li>
                                    <Link to="/contact">Contact Us</Link>
                                </li>

                                
                            </ul>
                        </div>
                    </div>

                   
                        {currentUser ?
                            <div className="col-lg-4  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
                                <div className="appie-btn-box text-right">
                                
                                
                                

                                    <a className={loginBtnCss} href="/login" onClick={logOut}><FiUser/> Logout</a>
                                    <a className="main-btn ml-30" href="/dashboard">Go To Dashboard</a>

                                    <div className="toggle-btn ml-30 canvas_open d-lg-none d-block">
                                        <i className="fa fa-bars"></i>
                                    </div>
                                </div> 
                            </div> 
                            :
                            <div className="col-lg-4  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
                                <div className="appie-btn-box text-right">
                                    
                                    <a className={loginBtnCss} href="/login"><FiUser/> Login</a>
                                    <a className="main-btn ml-30" href="/register">Get Started</a>

                                    <div className="toggle-btn ml-30 canvas_open d-lg-none d-block">
                                        <i className="fa fa-bars"></i>
                                    </div>
                                </div>
                            </div>
                        }
                    
                </div>
            </div>
        </div>
    </header>
  )
}

export default Navbar