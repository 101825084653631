import React from 'react'
import CanvasMenu from '../components/CanvasMenu'
import Navbar from '../components/navbar/Navbar'
import Team from '../components/team/Team'
import Footer from '../components/footer/Footer'
import abHeaderImg from '../assets/images/signup-thumb.png'
import Projects from '../components/project/Projects'

const About = () => {
  return (
    <div>
      <CanvasMenu/>
      <Navbar type="others"/>
      
    <div class="appie-page-title-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-page-title-item">
                        <h3 class="title">About Us</h3>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">About Us</li>
                            </ol>
                        </nav>
                        <div class="thumb">
                            <img src={abHeaderImg} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


      <section className="appie-about-page-area">
          <div className="container">
              <div className="row">
                  <div className="col-lg-8">
                      <div className="appie-about-page-content">
                          <h3 className="title">Join our team to create the best digital solutions.</h3>
                          <p>We provide a safe platform for all founders of all backgrounds who develop brilliant market fit products or have the next gen IDEA to build. 
                            Founders build purpose-driven companies around 
                            the technologies of the future and who have the passion and intuition to transform their expert ideas into real breakthroughs. 
                            </p>
                            <p>The world has greatly evolved with emerging technologies. 
                                Africa is not left out. We stand out to represent the under represented 
                                founders building world's breakthrough IDEAS and also digital natives who 
                                understand the needs of today’s consumers and want to improve their industries 
                                and challenge traditional approaches by using technology in different domains to transform and boost their social wellfare. 
                                They are entrepreneurs who want to contribute to building a better, more sustainable and equal world.</p>

                      </div>
                  </div>
              </div>
          </div>
      </section>

      <section className="appie-services-2-area pt-90 pb-55" id="service">
          <div className="container">
              <div className="row align-items-end">
                  <div className="col-lg-6 col-md-8">
                      <div className="appie-section-title">
                          <h3 className="appie-title">We’re driven by our values</h3>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-lg-4 col-md-6">
                      <div className="appie-single-service-2 appie-single-service-about mt-30 wow animated fadeInUp" 
                      data-wow-duration="2000ms" data-wow-delay="200ms">
                          <div className="icon">
                              <i className="fal fa-tv"></i>
                          </div>
                          <h4 className="title">Diversity</h4>
                          <p>We welcome new viewpoints/ventures. We believe the best social 
                            enterprises/teams are built with people from all walks of life</p>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                      <div className="appie-single-service-2 appie-single-service-about item-2 mt-30 wow animated fadeInUp" 
                      data-wow-duration="2000ms" data-wow-delay="400ms">
                          <div className="icon">
                              <i className="fal fa-code"></i>
                          </div>
                          <h4 className="title">Optimism</h4>
                          <p>We emphasize development, chance, and solutions. We try to strike a balance 
                            between zeal and realism.</p>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                      <div className="appie-single-service-2 appie-single-service-about item-3 mt-30 wow animated fadeInUp" 
                      data-wow-duration="2000ms" data-wow-delay="600ms">
                          <div className="icon">
                              <i className="fal fa-user-friends"></i>
                          </div>
                          <h4 className="title">User Interactive</h4>
                          <p>We passion for solutions and products that fit and benefit both parties</p>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                      <div className="appie-single-service-2 appie-single-service-about item-4 mt-30 wow animated fadeInUp" 
                      data-wow-duration="2000ms" data-wow-delay="200ms">
                          <div className="icon">
                              <i className="fal fa-mobile"></i>
                          </div>
                          <h4 className="title">Curiosity</h4>
                          <p>We drive ourselves and our partners to explore, learn and move beyond our comfort zones.</p>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                      <div className="appie-single-service-2 appie-single-service-about item-5 mt-30 wow animated fadeInUp" 
                      data-wow-duration="2000ms" data-wow-delay="400ms">
                          <div className="icon">
                              <i className="fal fa-retweet"></i>
                          </div>
                          <h4 className="title">Solid Teams</h4>
                          <p>We work with real people, responsible professionals and independent 
                            thinkers who communicate with integrity. </p>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                      <div className="appie-single-service-2 appie-single-service-about item-6 mt-30 wow animated fadeInUp" 
                      data-wow-duration="2000ms" data-wow-delay="600ms">
                          <div className="icon">
                              <i className="fal fa-bell"></i>
                          </div>
                          <h4 className="title">Honesty</h4>
                          <p>We are upfront, honest, and open. We construct connections based on mutual respect and trust.</p>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <Team/>
      <div className=''>
      <Projects/>
      </div>
      
      <Footer/>
      


    </div>
  )
}

export default About