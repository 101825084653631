import React from 'react'
import './categories.css'
import CategoryDetail from './CategoryDetail'
import DashboardSidebar from '../../../../components/dashboard/DashboardSidebar'
import DashbordNavbar from '../../../../components/dashboard/DashbordNavbar'

const Categories = () => {
  return (
    <div className='admin-dashboard'>
        <DashbordNavbar/>
        <DashboardSidebar/>
        <div className="dashboard-main">
        <CategoryDetail/>

      </div>
    </div>
  )
}

export default Categories