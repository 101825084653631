import React, {useState} from 'react'
import DashboardSidebar from '../components/dashboard/DashboardSidebar'
import DashbordNavbar from '../components/dashboard/DashbordNavbar'
import './dashboard.css'
import Overview from './dashboard/overview/Overview'
import AuthService from "../utils/Common"
// import { Navigate } from 'react-router-dom'
// import axios from 'axios'
import StartupsOverview from './dashboard/startupsview/StartupsOverview'

const Dashboard = () => {

  const currentUser = AuthService.getCurrentUser()

  const [userInfo, setUserInfo] = useState(currentUser);
 
 

  return (
    <div className='admin-dashboard'>
      <DashbordNavbar userInfo={userInfo} setUserInfo={setUserInfo}/>
      <DashboardSidebar userInfo={userInfo} setUserInfo={setUserInfo}/>
      
      <div className="dashboard-main">
        { userInfo.role === "startup" ?
          <Overview userInfo={userInfo} setUserInfo={setUserInfo}/>
          : userInfo.role === "vc" ?
          <StartupsOverview userInfo={userInfo} setUserInfo={setUserInfo}/>
          :
          <Overview userInfo={userInfo} setUserInfo={setUserInfo}/>
        }
        
        
      </div>
    </div>
  )
}

export default Dashboard