import React from 'react'
import Footer from '../components/footer/Footer'
import Navbar from '../components/navbar/Navbar'
import Startup from '../components/startup/Startup'
import abHeaderImg from '../assets/images/signup-thumb.png'


const StartUps = () => {
  return (
    <div className=''>
      <Navbar type="others"/>

      <div className="appie-page-title-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-page-title-item">
                            <h3 className="title">Innovative Startups around Africa</h3>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="#">The Brighter</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Future</li>
                                </ol>
                            </nav>
                            <div class="thumb">
                                <img src={abHeaderImg} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
      <section className="blogpage-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-7">
                        <div className="row">
                            {/* <Startup/>
                            <Startup/>
                            <Startup/>
                            <Startup/> */}
                            <h3><strong>Coming soon</strong></h3>
                          
                        </div>
                        {/* <div className="row">
                            <div className="col-lg-12">
                                <div className="bisylms-pagination">
                                    <span className="current">01</span>
                                    <a href="#">02</a>
                                    <a className="next" href="#">next<i className="fal fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="col-lg-4 col-md-5">
                        <div className="blog-sidebar">
                            <aside className="widget widget-search">
                                <form className="search-form" action="#" method="post">
                                    <input type="search" name="s" placeholder="Search..."/>
                                    <button type="submit"><i className="fal fa-search"></i></button>
                                </form>
                            </aside>
                            {/* <aside className="widget widget-categories">
                                <h3 className="widget-title">Categories</h3>
                                <ul>
                                    <li><a href="#">Web Design</a><span>(24)</span></li>
                                    <li><a href="#">Marketing</a><span>(15)</span></li>
                                    <li><a href="#">Frontend</a><span>(8)</span></li>
                                    <li><a href="#">IT & Software</a><span>(13)</span></li>
                                    <li><a href="#">Photography</a><span>(4)</span></li>
                                    <li><a href="#">Technology</a><span>(16)</span></li>
                                    <li><a href="#">General</a><span>(12)</span></li>
                                </ul>
                            </aside> */}
                            
                            {/* <aside className="widget">
                                 <h3 className="widget-title">Popular Tags</h3>
                                 <div className="tags">
                                    <a href="#">Bisy LMS</a>
                                    <a href="#">Design</a>
                                    <a href="#">General</a>
                                    <a href="#">Online</a>
                                    <a href="#">Prevention</a>
                                    <a href="#">Artist</a>
                                    <a href="#">Education</a>
                                    <a href="#">Motivation</a>
                                    <a href="#">Politico</a>
                                    <a href="#">Live Cases</a>
                                 </div>
                            </aside> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
    </div>
  )
}

export default StartUps