import React from 'react'
import './raise.css'
import RaisePhaseDetail from './RaisePhaseDetail'
import DashboardSidebar from '../../../../components/dashboard/DashboardSidebar'
import DashbordNavbar from '../../../../components/dashboard/DashbordNavbar'

const RaisePhase = () => {
  return (
    <div className='admin-dashboard'>
        <DashbordNavbar/>
        <DashboardSidebar/>
        <div className="dashboard-main">
        <RaisePhaseDetail/>

      </div>
    </div>
  )
}

export default RaisePhase