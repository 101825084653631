import axios from 'axios';
export   const apiURL = "https://api.startups4africa.com/api/";

const login = async (email, password) => {
    const response = await axios
          .post(apiURL + "login/", {
              email,
              password,
          });
      if (response.data.email) {
          sessionStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
  };

  const register = (firstname, lastname, email, password) => {
    return axios.post(apiURL + "signup/", {
      firstname,
      lastname,
      email,
      password,
    });
  };


  const AuthFunc = {
    
    login,
    register
  }

  export default AuthFunc;