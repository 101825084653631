import React from 'react'
import './stages.css'
import DevelopmentStageEditDetail from './DevelopmentStageEditDetail'
import DashboardSidebar from '../../../../components/dashboard/DashboardSidebar'
import DashbordNavbar from '../../../../components/dashboard/DashbordNavbar'

const DevelopmentStageEdit = () => {
  return (
    <div className='admin-dashboard'>
        <DashbordNavbar/>
        <DashboardSidebar/>
        <div className="dashboard-main">
        <DevelopmentStageEditDetail/>

      </div>
    </div>
  )
}

export default DevelopmentStageEdit