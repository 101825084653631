import React, {useEffect, useState} from 'react'
import './dashboardsidebar.css'
import logoImg from '../../assets/images/logo.png'
import {BiHomeSmile,BiBookAlt,BiHelpCircle, BiLogOutCircle, BiCategoryAlt} from 'react-icons/bi'
import {GiEgyptianProfile} from 'react-icons/gi'
import {BsCardList,BsFolder,BsBricks,BsBarChartLine} from 'react-icons/bs'
import {RiSettings4Line} from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { removeUserSession } from "../../utils/Common";
import axios from "axios";

import AuthService, {apiURL} from '../../utils/Common'


const DashboardSidebar = ({userInfo, setUserInfo}) => {
    const[errorMessage, setErrorMessage] = useState('')
    const currentUser = AuthService.getCurrentUser()
    const token = currentUser.access_token;
    const userRole = currentUser.role

    // =============================USER ID
    // get user ID and access token
    const user_uuid = currentUser.user_uuid;
    // console.log(userRole);

  

    // ====================
  //   using access token as authorisation for bearer to pass authenticated request for updates
    const config = {
      headers: { 
          "Authorization": `Token ${token}` 
      }
  };

// =========================GET AUTHENTICATED USER===================================

    // const[userData, setUserData] = useState([]);
    const getUserUrl = `${apiURL}users/get/${user_uuid}/`
    useEffect(() => {
        const getUserData = (user_uuid) => {
            return axios.get(getUserUrl, config)
                .then((response) => {
                    if (response.status === 200) {
                    setUserInfo(response.data)
                    }else Promise.reject()
                }).catch((error) => {
                    // setErrorMessage(error.response.message)
                })
        }; getUserData()
    }, [user_uuid]);

    // console.log(userInfo);


//   ======================LOGOUT USER====================================

  const logoutUser = async () => {

    localStorage.removeItem("login")
    sessionStorage.removeItem("user");
    sessionStorage.clear();
    localStorage.removeItem("login")
    return axios.get(apiURL + "logout/", config)
        .then((response) => {
        return response.data;
        });
    // return
  };

//   =====================Define Route

   

   

  return (
    <nav className='sidenav' id='mySidenav'>
        <span className='menubt' onClick="openNav()">&#9776; open</span>
        <div className='dashboardSidebar sidebar-nav d-none d-sm-block'>
            <div className="dasboardSidebar-container">
                <div className="dasboardSidebar-top">
                    <Link to="/"><img className='sidebar-logo' src={logoImg} alt="startup4 logo"  /></Link>
                    
                { userRole === 'startup' ?
                
                        // ======initial Plan was to make this Startup
                        <ul className='dashboardSidebar-links'>
                            <li>
                                <Link to="/dashboard/startups/create/">
                                    <BiBookAlt className='sidebar-icons' />Create Startup
                                </Link>
                            </li>

                            <li>                        
                                <Link  to="/dashboard">
                                    <BiHomeSmile className='sidebar-icons' />My Dashboard
                                </Link>
                            </li>
                        </ul> 

                        // ================We Think this should be VCx
                        : userRole === 'vc' ?
                        <ul className='dashboardSidebar-links'>
                    
                            {/* <li>                        
                                <Link  to="/dashboard">
                                    <BiHomeSmile className='sidebar-icons' />My Dashboard
                                </Link>
                            </li> */}

                            <li>
                                <Link to="/dashboard/">
                                    <BsCardList className='sidebar-icons' />All Startups</Link>
                            </li>

                            <li>
                                <Link to="/dashboard/vc/connected_startups/list/">
                                    <BsFolder className='sidebar-icons' />
                                    My Preferred startups</Link>
                            </li>
                            <li>
                                <Link to="/dashboard/vc/investment/profile/">
                                    <BsFolder className='sidebar-icons' />
                                    My Investor Profile</Link>
                            </li>
                        
                            
                        </ul>
                        :
                        // ========= Supposedly Admin
                        <ul className='dashboardSidebar-links'>
                            <li>
                                <Link to="/dashboard/startups/create/">
                                    <BiBookAlt className='sidebar-icons' />Create Startup
                                </Link>
                            </li>
                    
                            <li>                        
                                <Link  to="/dashboard">
                                    <BiHomeSmile className='sidebar-icons' />My Dashboard
                                </Link>
                            </li>

                            <li>
                                <Link to="/dashboard/overview/allstartups/">
                                    <BsCardList className='sidebar-icons' />All Startups</Link>
                            </li>

                            <li><Link to="/dashboard/vc/connected_startups/list/">
                                <BsFolder className='sidebar-icons' />My Preferred startups</Link></li>
                        
                            <li>
                                <Link to="/dashboard/users/">
                                    <BsBricks className='sidebar-icons' />All Users
                                </Link>
                            </li>
                            <li>
                                <Link to="/dashboard/admin/setup/categories/">
                                    <BiCategoryAlt className='sidebar-icons' />Startup Category
                                </Link>
                            </li>
                            <li>
                                <Link to="/dashboard/admin/setup/dev_stages/">
                                    <BiCategoryAlt className='sidebar-icons' />Startup Development Stage
                                </Link>
                            </li>
                            <li>
                                <Link to="/dashboard/admin/setup/raise_phase/">
                                    <BiCategoryAlt className='sidebar-icons' />Raise Phase
                                </Link>
                            </li>
                        </ul>

                }             
                </div>
                
                {/* Bottom Menu */}
                <div className="dasboardSidebar-buttom">
                    <li>
                        <Link  to="/dashboard/settings/">
                            <GiEgyptianProfile className='sidebar-icons' />Settings
                        </Link>
                        
                    </li>
                    <li>
                        <Link to="/login" onClick={logoutUser}>
                            <BiLogOutCircle className='sidebar-icons' />logout
                        </Link>
                    
                    </li>
                </div>
            </div>
            
        </div>
    </nav>
  )
}

export default DashboardSidebar