import React from 'react'
import './vc.css'
import VcDetails from './VcDetails'
import DashboardSidebar from '../../../components/dashboard/DashboardSidebar'
import DashbordNavbar from '../../../components/dashboard/DashbordNavbar'


const Vc = () => {
  return (
      <div className='admin-dashboard'>
        <DashbordNavbar/>
        <DashboardSidebar/>
        <div className="dashboard-main">
          <VcDetails/>

        </div>
      </div>
  )
}

export default Vc