import React from 'react'
import './startups.css'
import StartupSettingDetail from './StartupSettingDetail'
import DashboardSidebar from '../../../../components/dashboard/DashboardSidebar'
import DashbordNavbar from '../../../../components/dashboard/DashbordNavbar'
import CreateStartUp from './create_startup/CreateStartUp'


const StartupSettings = () => {
  return (
    <div className='admin-dashboard'>
        <DashbordNavbar/>
        <DashboardSidebar/>
        <div className="dashboard-main">
        {/* <StartupSettingDetail/> */}
        <CreateStartUp/>

      </div>
    </div>
  )
}

export default StartupSettings