import React, {useState, useEffect} from 'react'
import './vc.css'
import AuthService, {apiURL, imgUrl} from "../../../utils/Common"
import axios from 'axios'
import { config } from '../overview/Ov'
import { OverviewNew } from '../overview/OverviewNew'


const VcDetails = () => {
 

  const currentUser = AuthService.getCurrentUser()
  const [vcstartupData, setVcStartuprData] = useState([])
  const [loading, setLoading] = useState(false);
  const [disconnect, setDisconnect] = useState('')

  
    const url = apiURL + "vc/connected/list/"
    

    useEffect(() =>{
   
      const getVcStartups = async() => {
          setLoading(true)
          return axios.get(url, config)
           .then(response => {
            setVcStartuprData(response.data)
              console.log(response.data);
              setLoading(false)
            return 
          })
        };
        getVcStartups()

    },[])
  
   
      
    // =====================DELETE CATEGORY BY UUID =============================================
    // const disconnectUrl =  apiURL + "vc/disconnect/" +`${isStartup.startup_uuid}/`


    const disconnectStartup = async (index) => {
        setDisconnect(true)
        return axios.get(apiURL + "vc/disconnect/" +`${index}/` ,config)
            .then(response => {
                if (response.status === 200) {
                    alert("Disconnected Startup from my List");
                    console.log(response.data);
                    setVcStartuprData(response.data)
                    
                    // setSuccessMessage("Category Deleted successfully")
                    setDisconnect(false)
                    window.location.reload();
                  }else Promise.reject();
                
            return 
        }).catch(error => {
            setDisconnect(false)
            console.log(error.response.message)
        })
    }


  return (
    <div>
        <section>
            <div className="overview-title">
                <h1>Preference Startups</h1>
            </div>
            <div className="overview-container">
            {loading ? 
            <div className="loading"> Loading...</div>
              :
              <>
                {vcstartupData.length === "" ?
                  <>You don't have any startups Listed as Preferred 
                    <br />
                    Please Visit ALL startups Link to connect with desired startups.
                  </>
                  :
                  vcstartupData.map((startup) => (<OverviewNew key={startup.startup_uuid} startup={startup} />))

                }
              </>
            }
            </div>

            {/* <div className="overview-container">
                <OverviewCard/>
            </div> */}
        </section>
    </div>
  )
}

export default VcDetails